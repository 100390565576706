import { merge } from "lodash";
import { Theme } from "@mui/material";
import Accordion from "./Accordion";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Chip from "./Chip";
import Dialog from "./Dialog";
import Grid from "./Grid";
import Input from "./Input";
import List from "./List";
import Stepper from "./Stepper";
import Table from "./Table";
import Textfield from "./Textfield";
import Tooltip from "./Tooltip";

export default function ComponentsOverrides(theme: Theme) {
    return merge(
        Accordion(theme),
        Button(theme),
        Checkbox(theme),
        Chip(theme),
        Dialog(),
        Grid(),
        Input(theme),
        List(theme),
        Stepper(theme),
        Table(theme),
        Textfield(theme),
        Tooltip(theme),
    );
}
