import React, { useCallback, useState } from "react";
import {
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { INotification } from "types/Models";
import { StyledButton } from "components/StyledButton";
import { api } from "api/api";

interface IDeleteNotifPropsType {
    data: INotification;
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
    needUpdates: boolean;
    setNeedUpdates: (value: boolean) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

export function DeleteCell({
    data,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setNeedUpdates,
    needUpdates,
    needTableUpdate,
    setTableUpdate,
}: IDeleteNotifPropsType) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => setDialogOpen(false);
    const onNotificationDelete = () => {
        setDialogOpen(true);
    };
    const agreeToDelete = useCallback(() => {
        setLoaderOpen(true);
        api.post("onboarding/notifications/delete", { id: data.id })
            .then((result) => {
                setSnackType("success");
                setSnackMessage(result.data.description);
                setSnackOpen(true);
                setLoaderOpen(false);
                setNeedUpdates(!needUpdates);
                setTableUpdate(!needTableUpdate);
                setDialogOpen(false);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
                setDialogOpen(false);
            });
    }, [data]);
    return (
        <>
            <Stack height="70px">
                <Typography
                    variant="button"
                    color="text.disabled"
                    margin="auto"
                    onClick={onNotificationDelete}
                    sx={{ cursor: "pointer" }}
                    className="secondarySelection"
                >
                    Delete
                </Typography>
            </Stack>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle sx={{ fontSize: "18px" }}>
                    Are you sure you want to delete this notification?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>This action cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={agreeToDelete}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.grey[300],
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                        isAccept
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
