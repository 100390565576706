import React from "react";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as download } from "assets/Images/download2.svg";

export function File({ fileName, fileURL }: { fileName: string; fileURL: string }) {
    return fileName ? (
        <Stack direction="row" alignItems="center" spacing={1.5}>
            <a href={fileURL} download>
                <SvgIcon
                    component={download}
                    inheritViewBox
                    sx={{
                        color: ({ palette }) => palette.info.main,
                        fontSize: "18px",
                        fill: "transparent",
                        stroke: ({ palette }) => palette.info.main,
                    }}
                />
            </a>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: ({ palette }) => palette.info.main,
                }}
            >
                {fileName || ""}
            </Typography>
        </Stack>
    ) : null;
}
