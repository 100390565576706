import React, { useCallback, useRef, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as MenuIcon } from "assets/Images/menu.svg";
import { api } from "api/api";
import { IUser } from "types/Models";
import { Modal } from "./Modal";
import { EditUserModal } from "./EditUserModal";

interface IActionMenuProps {
    user: IUser;
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: string) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

interface IMenuProps {
    data: IUser;
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: string) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

export function MenuContent({
    user,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setTableUpdate,
    needTableUpdate,
}: IActionMenuProps) {
    const ref = useRef(null);
    const [chosenUser, setChosenUser] = useState<IUser>();
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);

    const doEditUser = useCallback(() => {
        setIsOpen(false);
        setEditModalOpen(true);
    }, [chosenUser]);

    const doDeleteUser = useCallback(() => {
        setModalOpen(true);
        setIsOpen(false);
    }, [chosenUser]);

    const agreeToDelete = useCallback(() => {
        setLoaderOpen(true);
        api.post("/users/admins/delete", { email: chosenUser?.email })
            .then(() => {
                setSnackType("success");
                setSnackMessage("You successfully deleted user");
                setSnackOpen(true);
                setLoaderOpen(false);
                setTableUpdate(!needTableUpdate);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            })
            .finally(() => setModalOpen(false));
    }, [chosenUser]);

    const agreeToEdit = useCallback(
        (values: { role: string }) => {
            setLoaderOpen(true);
            api.post("/users/admins/edit", { ...chosenUser, ...values })
                .then(() => {
                    setSnackType("success");
                    setSnackMessage("You successfully edited user's role");
                    setSnackOpen(true);
                    setLoaderOpen(false);
                    setTableUpdate(!needTableUpdate);
                })
                .catch((err) => {
                    setSnackType("error");
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                    setLoaderOpen(false);
                })
                .finally(() => setEditModalOpen(false));
        },
        [chosenUser],
    );

    const doChooseUser = useCallback(() => {
        setChosenUser(user);
    }, [user]);

    return (
        <>
            <IconButton
                ref={ref}
                onClick={() => {
                    doChooseUser();
                    setIsOpen(true);
                }}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={doEditUser}>Edit Role</MenuItem>
                <MenuItem onClick={doDeleteUser}>Delete User</MenuItem>
            </Menu>
            <Modal
                type="error"
                isModalOpen={isModalOpen}
                userEmail=""
                setModalOpen={setModalOpen}
                handleClick={agreeToDelete}
            />
            <EditUserModal
                setModalOpen={setEditModalOpen}
                isModalOpen={isEditModalOpen}
                agreeToEdit={agreeToEdit}
            />
        </>
    );
}

export function ActionMenu({
    data,
    setLoaderOpen,
    setSnackType,
    setSnackMessage,
    setSnackOpen,
    setTableUpdate,
    needTableUpdate,
}: IMenuProps) {
    return (
        <MenuContent
            user={data}
            setLoaderOpen={setLoaderOpen}
            setSnackType={setSnackType}
            setSnackMessage={setSnackMessage}
            setSnackOpen={setSnackOpen}
            needTableUpdate={needTableUpdate}
            setTableUpdate={setTableUpdate}
        />
    );
}
