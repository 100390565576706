import React, { useEffect, useLayoutEffect, useState } from "react";
import { Frame } from "components/Frame";
import Page from "components/Page";
import { getInfo, getNotifications, getSubmissions } from "pages/Dashboard/Dashboard.service";
import { INotification, IStatsInfo } from "types/Models";
import { Dashboard } from "pages/Dashboard/Dashboard";
import { Loader } from "components/Loader";
import { AlertColor } from "@mui/material";
import { Notifications } from "pages/Notifications/Notifications";
import { UserManagement } from "pages/UserManagement/UserManagement";
import { CreateUser } from "pages/UserManagement/CreateUser";
import { Submissions } from "../pages/Submissions/Submissions";
import { SnackBar } from "./SnackBar";

export function CommonPage({ type }: { type: string }) {
    const [info, setInfo] = useState<IStatsInfo | undefined>(undefined);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [submissions, setSubmissions] = useState([]);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState<AlertColor>("error");
    const [needTableUpdate, setTableUpdate] = useState(false);
    useLayoutEffect(() => {
        getInfo()
            .then((result) => {
                setInfo(result.data.data);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            });
    }, []);
    useLayoutEffect(() => {
        getNotifications()
            .then((result) => {
                setNotifications(result.data.data);
                setNotificationsCount(result.data.totalCount);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            });
    }, [needTableUpdate]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoaderOpen(true);
            getNotifications()
                .then((result) => {
                    setNotifications(result.data.data);
                    setNotificationsCount(result.data.totalCount);
                })
                .catch((err) => {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                })
                .finally(() => setLoaderOpen(false));
        }, 600000);
        return () => clearInterval(intervalId);
    }, []);

    useLayoutEffect(() => {
        setLoaderOpen(true);
        getSubmissions()
            .then((result) => {
                setSubmissions(result.data.data);
                setLoaderOpen(false);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            });
    }, [needTableUpdate]);
    const content = {
        submissions: (
            <Submissions
                submissions={submissions}
                setLoaderOpen={setLoaderOpen}
                setSnackType={setSnackType}
                setSnackMessage={setSnackMessage}
                setSnackOpen={setSnackOpen}
                needTableUpdate={needTableUpdate}
                setTableUpdate={setTableUpdate}
            />
        ),
        dashboard: (
            <Dashboard
                info={info}
                notifications={notifications}
                submissions={submissions}
                setLoaderOpen={setLoaderOpen}
                setSnackType={setSnackType}
                setSnackMessage={setSnackMessage}
                setSnackOpen={setSnackOpen}
                needTableUpdate={needTableUpdate}
                setTableUpdate={setTableUpdate}
            />
        ),
        notifications: (
            <Notifications
                setLoaderOpen={setLoaderOpen}
                setSnackType={setSnackType}
                setSnackMessage={setSnackMessage}
                setSnackOpen={setSnackOpen}
                needTableUpdate={needTableUpdate}
                setTableUpdate={setTableUpdate}
            />
        ),
        userManagement: (
            <UserManagement
                setLoaderOpen={setLoaderOpen}
                setSnackType={setSnackType}
                setSnackMessage={setSnackMessage}
                setSnackOpen={setSnackOpen}
            />
        ),
        createUser: (
            <CreateUser
                setLoaderOpen={setLoaderOpen}
                setSnackType={setSnackType}
                setSnackMessage={setSnackMessage}
                setSnackOpen={setSnackOpen}
            />
        ),
    };
    const title = {
        submissions: "Submissions | Staff Portal",
        dashboard: "Dashboard | Staff Portal",
        notifications: "Notifications | Staff Portal",
        userManagement: "User Management | Staff Portal",
        createManagement: "User Management | Staff Portal",
    };
    return (
        <Page title={title[type as keyof typeof title]}>
            <Frame
                notificationsCount={notificationsCount}
                color={type === "createUser" ? "common.white" : "background.default"}
            >
                {content[type as keyof typeof content]}
            </Frame>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType={snackType}
            />
        </Page>
    );
}
