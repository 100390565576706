import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container, SvgIcon } from "@mui/material";
import Page from "components/Page";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";

const RootPage = styled(Page)(() => ({
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    margin: "auto",
}));

export default function Page404() {
    return (
        <RootPage title="404 Page Not Found | Staff Portal">
            <Container>
                <Box sx={{ maxWidth: "480px", margin: "auto", textAlign: "center" }}>
                    <Typography variant="h3" paragraph>
                        Sorry, page not found!
                    </Typography>
                    <Typography color="text.secondary">
                        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped
                        the URL? Be sure to check your spelling.
                    </Typography>
                    <SvgIcon
                        sx={{
                            fontSize: "200px !important",
                            fill: ({ palette }) => palette.primary.main,
                        }}
                        component={SearchOffOutlinedIcon}
                    />

                    <div>
                        <Button
                            to="/dashboard"
                            size="large"
                            variant="contained"
                            component={RouterLink}
                            sx={{
                                color: "primary.contrastText",
                                backgroundColor: "primary.main",
                                "&:hover": {
                                    color: "primary.main",
                                },
                            }}
                        >
                            Go to Home
                        </Button>
                    </div>
                </Box>
            </Container>
        </RootPage>
    );
}
