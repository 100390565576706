import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import useAuthService from "hooks/useAuthService";
import { useNavigate } from "react-router-dom";
import { routePaths } from "routes/routePaths";
import { StyledButton } from "./StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.text.disabled,
        "& .MuiSvgIcon-root": { fill: theme.palette.text.disabled },
    },
}));

export function LogoutButton() {
    const classes = useStyles();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuthService();
    const handleClose = () => setDialogOpen(false);
    const agreeToLogout = () => {
        logout();
        navigate(routePaths.login);
    };
    return (
        <>
            <ListItemButton className={classes.root} onClick={() => setDialogOpen(true)}>
                <ListItemIcon
                    sx={{
                        stroke: ({ palette }) => palette.text.disabled,
                    }}
                >
                    <LogoutRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="h4">Log out</Typography>} />
            </ListItemButton>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <Typography sx={{ pt: "30px", textAlign: "center" }}>
                    Are you sure you want to log out?
                </Typography>
                <DialogActions sx={{ p: "30px" }}>
                    <Button
                        onClick={agreeToLogout}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.info.contrastText,
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                        isAccept
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
