import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginPage } from "pages/LoginPage/LoginPage";
import useAuthService from "hooks/useAuthService";
import Page404 from "pages/404Page";
import { CommonPage } from "components/CommonPage";
import { SubmissionReviewPage } from "pages/SubmissionReview/SubmissionReviewPage";
import { BusinessInfo } from "pages/SubmissionReview/components/BusinessInfo";
import { Personalization } from "pages/SubmissionReview/components/Personalization";
import { KYC } from "pages/SubmissionReview/components/KYC";
import { API } from "pages/SubmissionReview/components/API";
import { DevConsole } from "pages/SubmissionReview/components/DevConsole";
import { Approval } from "pages/SubmissionReview/components/Approval";
import { routePaths } from "./routePaths";

type ProtectedRoutePropType = {
    element: JSX.Element;
};

function ProtectedRoute({ element }: ProtectedRoutePropType): JSX.Element {
    const { isAuthenticated } = useAuthService();
    if (!isAuthenticated) {
        return <Navigate to={routePaths.login} replace />;
    }
    return element;
}

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={routePaths.login} replace />} />
            <Route path={routePaths.login} element={<LoginPage />} />
            <Route
                path={routePaths.dashboard}
                element={<ProtectedRoute element={<CommonPage type="dashboard" />} />}
            />
            <Route
                path={routePaths.submissions}
                element={<ProtectedRoute element={<CommonPage type="submissions" />} />}
            />
            <Route
                path={routePaths.notifications}
                element={<ProtectedRoute element={<CommonPage type="notifications" />} />}
            />
            <Route
                path={routePaths.user_management}
                element={<ProtectedRoute element={<CommonPage type="userManagement" />} />}
            />
            <Route
                path={routePaths.create_user}
                element={<ProtectedRoute element={<CommonPage type="createUser" />} />}
            />
            <Route
                path={routePaths.review}
                element={<ProtectedRoute element={<SubmissionReviewPage />} />}
            >
                <Route index element={<BusinessInfo />} />
                <Route path={routePaths.personalization_review} element={<Personalization />} />
                <Route path={routePaths.kyc_review} element={<KYC />} />
                <Route path={routePaths.api_review} element={<API />} />
                <Route path={routePaths.dev_console_review} element={<DevConsole />} />
                <Route path={routePaths.approval_review} element={<Approval />} />
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}
