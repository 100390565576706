import React from "react";
import { Stack, Typography } from "@mui/material";
import { InfoItem } from "components/InfoItem";
import { DevConsoleType } from "types/Models";

export function DevConsoleSection({ data }: { data: DevConsoleType }) {
    return data?.clientId && data.clientSecret ? (
        <Stack spacing={3}>
            <InfoItem name="Client ID" value={data.clientId} />
            <InfoItem name="Secret Key" value={data.clientSecret} />
        </Stack>
    ) : (
        <Typography variant="body1" color="error.dark" fontStyle="italic">
            No information to display. Please try again
        </Typography>
    );
}
