/* eslint-disable no-param-reassign */
import React from "react";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { StyledButton } from "components/StyledButton";
import { useNavigate } from "react-router-dom";
import { api } from "api/api";
import { useData } from "../SubmissionReviewPage";

interface IFooterPropsType {
    formValues: IBody;
    url: string;
    nextStep: string;
    onBack: () => void;
}

interface IBody {
    reviewStatus?: string;
    iswPortalReviewStatus?: string;
    institutionID?: number;
    message: string;
    status: string | undefined;
}

export function SectionFooter({ formValues, url, nextStep, onBack }: IFooterPropsType) {
    const navigate = useNavigate();
    const { instId, setSnackMessage, setSnackOpen, setSnackType, needUpdates, setNeedUpdates } =
        useData();

    const onSubmit = (values: IBody) => {
        api.post(url, {
            institutionID: instId,
            message: values.message || "",
            status: values.status,
        })
            .then(() => {
                setNeedUpdates(!needUpdates);
                setSnackMessage(
                    `${
                        values.status === "APPROVED"
                            ? "The step was approved"
                            : "You successfully requested updates"
                    }`,
                );
                setSnackType("success");
                setSnackOpen(true);
                navigate(nextStep);
            })
            .catch(() => {
                setSnackMessage("This operation failed");
                setSnackType("error");
                setSnackOpen(true);
            });
    };
    return (
        <Stack>
            <Box
                sx={{
                    width: "100%",
                    height: "1px",
                    margin: "auto",
                    m: "37px 0",
                    bgcolor: "#C4C4C4",
                }}
            />

            <Formik
                initialValues={{
                    status: formValues
                        ? formValues.iswPortalReviewStatus || formValues.reviewStatus
                        : "",
                    message: formValues?.message || "",
                }}
                onSubmit={() => console.log("here")}
                enableReinitialize
            >
                {({ values, handleChange }) => (
                    <Form id="login" data-testid="form" autoComplete="off">
                        <RadioGroup row name="status" value={values.status} onChange={handleChange}>
                            <FormControlLabel
                                value="APPROVED"
                                control={<Radio />}
                                label="Approve"
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: ({ palette }) => palette.primary.dark,
                                        fontWeight: 600,
                                        fontSize: "17px",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="UPDATE_REQUESTED"
                                control={<Radio />}
                                label="Request update"
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: ({ palette }) => palette.primary.dark,
                                        fontWeight: 600,
                                        fontSize: "17px",
                                    },
                                }}
                            />
                        </RadioGroup>
                        {values.status === "UPDATE_REQUESTED" && (
                            <TextField
                                autoComplete="new-password"
                                sx={{
                                    mt: "30px",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#ebebeb !important",
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        backgroundColor: "white",
                                        fontSize: 14,
                                        color: "#6D7E95",
                                    },
                                }}
                                name="message"
                                onChange={handleChange}
                                value={values.message}
                                placeholder="Enter reason"
                            />
                        )}
                        <Stack
                            direction="row"
                            sx={{ gap: "35px", mt: "88px", ml: "-40px", position: "absolute" }}
                        >
                            <Button variant="outlined" onClick={onBack}>
                                Back
                            </Button>
                            <StyledButton
                                text="Save and continue"
                                width="197px"
                                isAccept
                                onHandleClick={() => onSubmit(values)}
                                isDisabled={
                                    values.status === "PENDING_REVIEW" ||
                                    values.status === "REVIEW_IN_PROGRESS" ||
                                    (values.status === "UPDATE_REQUESTED" && !values.message)
                                }
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Stack>
    );
}
