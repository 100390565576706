import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Button } from "@mui/material";

type StyledButtonProps = {
    text: string;
    width: string;
    isAccept: boolean;
    isDisabled: boolean;
    onHandleClick: () => void;
    autoFocus?: boolean;
};

export function StyledButton({
    text,
    width,
    isAccept,
    isDisabled,
    onHandleClick,
    autoFocus,
}: StyledButtonProps) {
    const MyStyledButton = styled(Button)(({ theme }) => ({
        width: `${width}`,
        backgroundColor: isAccept ? theme.palette.primary.main : theme.palette.error.light,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: alpha(
                isAccept ? theme.palette.primary.main : theme.palette.error.light,
                0.7,
            ),
        },
        "&:disabled": {
            color: theme.palette.text.disabled,
        },
    }));
    return (
        <MyStyledButton
            disabled={isDisabled}
            onMouseDown={(e) => {
                if (e.button === 0) {
                    onHandleClick();
                }
            }}
            autoFocus={autoFocus}
        >
            {text}
        </MyStyledButton>
    );
}

StyledButton.defaultProps = {
    autoFocus: false,
};
