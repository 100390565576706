import React from "react";
import ReactDOM from "react-dom/client";
import ThemeConfig from "styles";
import { App } from "./App";
import "./styles/index.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ThemeConfig>
            <App />
        </ThemeConfig>
    </React.StrictMode>,
);
