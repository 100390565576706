import React from "react";
import { AlertColor, Box, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IStatsInfo, INotification } from "types/Models";
import { ISubmission } from "api/apiExample";
import { InfoSection } from "./Info/InfoSection";
import { NotificationsSection } from "./Notifications/NotificationsSection";
import { SubmissionsSection } from "./SubmissionsSection/SubmissionsSection";

interface IInfoProps {
    info: IStatsInfo | undefined;
    notifications: INotification[];
    submissions: ISubmission[];
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    inner: { padding: "25px", width: "100%", height: "100%", gap: "25px", marginBottom: "50px" },
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

export function Dashboard({
    info,
    notifications,
    submissions,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setTableUpdate,
    needTableUpdate,
}: IInfoProps) {
    const classes = useStyles();
    return (
        <Stack>
            <Stack className={classes.inner}>
                <Typography variant="h3" color="action.focus" marginBottom="12px">
                    Dashboard
                </Typography>
                <Stack direction="row" spacing={2.5}>
                    <Box width="25%">
                        <InfoSection info={info} />
                    </Box>
                    <Box width="75%" height="100%">
                        <NotificationsSection notifications={notifications} />
                    </Box>
                </Stack>
                <Box height="455px" width="100%">
                    <SubmissionsSection
                        submissions={submissions}
                        setLoaderOpen={setLoaderOpen}
                        setSnackType={setSnackType}
                        setSnackMessage={setSnackMessage}
                        setSnackOpen={setSnackOpen}
                        needTableUpdate={needTableUpdate}
                        setTableUpdate={setTableUpdate}
                    />
                </Box>
            </Stack>
        </Stack>
    );
}
