import React from "react";
import { Stack, Box, Typography, SvgIcon } from "@mui/material";
import { ReactComponent as closeIcon } from "assets/Images/closeIcon.svg";

interface IModalPropType {
    setModalOpen: (value: boolean) => void;
    modalHeader: string;
    modalMessage: string;
    type: string;
}

export function SmallNotification({
    setModalOpen,
    modalHeader,
    modalMessage,
    type,
}: IModalPropType) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            position="absolute"
            sx={{
                width: "35%",
                maxWidth: "600px",
                p: "12px",
                top: "50px",
                right: "60px",
                backgroundColor: type === "error" ? "#FFF1F1" : "#F1FEF1",
                borderRadius: "6px",
                border: type === "error" ? "1px solid #EF4444" : "1px solid #BEF2B9",
                borderLeft: type === "error" ? "6px solid #DC4437" : "6px solid #36743D",
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        color: "#163C29",
                    }}
                >
                    {modalHeader}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: "#163C29",
                    }}
                >
                    {modalMessage}
                </Typography>
            </Box>
            <SvgIcon
                component={closeIcon}
                inheritViewBox
                sx={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
            />
        </Stack>
    );
}
