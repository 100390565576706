import React from "react";
import { Box, Icon, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export interface IInfoItem {
    id: string | number;
    title: string;
    value: string | number;
    icon: React.ReactNode;
    color: string;
}

interface IInfoCardProps {
    item: IInfoItem;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: "18px 25px",
        backgroundColor: theme.palette.common.white,
        border: "1px solid",
        borderColor: "#ebebeb",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
    },
    icon: {
        width: "54px",
        height: "54px",
        borderRadius: "50%",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}));

export function InfoItem({ item }: IInfoCardProps) {
    const classes = useStyles();
    return (
        <Stack className={classes.card}>
            <Typography variant="subtitle1" color="text.disabled">
                {item.title}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2" color="common.black">
                    {item.value}
                </Typography>
                <Box className={classes.icon} sx={{ backgroundColor: item.color }}>
                    <Icon
                        color="primary"
                        sx={{ width: "100%", height: "100%", fontSize: "26px", pt: "11px" }}
                    >
                        {item.icon}
                    </Icon>
                </Box>
            </Stack>
        </Stack>
    );
}
