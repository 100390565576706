import React from "react";
import { Stack, Typography } from "@mui/material";
import { Logo } from "components/Logo";

export function Header() {
    return (
        <Stack spacing={7} data-testid="header">
            <Stack direction="row" spacing={5}>
                <Logo width="240px" height="83px" />
                <Typography variant="overline" alignSelf="center" pt="14px" color="secondary">
                    STAFF PORTAL
                </Typography>
            </Stack>
            <Typography variant="h1" color="common.white">
                Login to your account
            </Typography>
        </Stack>
    );
}
