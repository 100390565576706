/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { Stack, Box, TextField, Typography, IconButton, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikErrors } from "formik";
import * as yup from "yup";
import { Loader } from "components/Loader";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { StyledButton } from "components/StyledButton";
import useAuthService from "hooks/useAuthService";
import { routePaths } from "routes/routePaths";
import { makeStyles } from "@mui/styles";

type LoginBodyType = {
    email: string;
    password: string;
    isRemembered: boolean;
    mode: string;
};

const useStyles = makeStyles(() => ({
    input: {
        boxShadow: "0 0 0px 1000px #021921 inset",
        textFillColor: "white",
    },
}));

export const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("This field is required"),
    password: yup.string().trim().required("This field is required"),
});

export function LoginForm() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { login } = useAuthService();
    const [isPassVisible, setPassVisibility] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const handlePassClick = () => {
        setPassVisibility(!isPassVisible);
    };
    const currentUserEmail = localStorage.getItem("currentUserEmail");
    const currentUserPassword = localStorage.getItem("currentUserPassword");
    const onSubmitForm = (values: LoginBodyType, errors: FormikErrors<LoginBodyType>) => {
        setLoaderOpen(true);
        login({ ...values })
            .then(() => {
                setLoaderOpen(false);
                navigate(routePaths.dashboard);
                if (values.isRemembered) {
                    localStorage.setItem("currentUserEmail", values.email);
                    localStorage.setItem("currentUserPassword", values.password);
                }
            })
            .catch((err) => {
                if (err.response) {
                    switch (err?.response?.request?.status) {
                        case 400:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        case 401:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        case 403:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        case 404:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        default:
                            errors.email = `${err.message} error`;
                    }
                }
                setLoaderOpen(false);
            });
    };
    return (
        <>
            <Formik<LoginBodyType>
                initialValues={{
                    email: currentUserEmail || "",
                    password: currentUserPassword || "",
                    isRemembered: false,
                    mode: "ADMIN",
                }}
                onSubmit={() => console.log("here")}
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, handleChange, setErrors, touched }) => (
                    <Form id="login" data-testid="form" autoComplete="off">
                        <Stack width="100%">
                            <Box mt="20px">
                                <Box>
                                    <Typography variant="subtitle1" color="primary">
                                        Email Address
                                    </Typography>
                                    <TextField
                                        autoComplete="new-password"
                                        inputProps={{
                                            className: classes.input,
                                            style: {
                                                fontSize: 16,
                                                border: "white",
                                            },
                                        }}
                                        variant="outlined"
                                        name="email"
                                        id="email"
                                        error={errors.email !== undefined && touched.email}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, email: undefined });
                                        }}
                                        value={values.email}
                                        placeholder="Enter email address"
                                    />
                                    {errors.email && touched.email ? (
                                        <Typography variant="body2" color="secondary" height="40px">
                                            <ErrorRoundedIcon
                                                fontSize="small"
                                                color="inherit"
                                                sx={{ position: "relative", top: "6px", mr: "5px" }}
                                            />
                                            {errors.email}
                                        </Typography>
                                    ) : (
                                        <Typography width="19px" height="40px" />
                                    )}
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1" color="primary">
                                        Password
                                    </Typography>
                                    <TextField
                                        autoComplete="new-password"
                                        inputProps={{
                                            className: classes.input,
                                            style: {
                                                fontSize: 16,
                                                border: "white",
                                            },
                                        }}
                                        // eslint-disable-next-line react/jsx-no-duplicate-props
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton
                                                    onClick={handlePassClick}
                                                    edge="end"
                                                    color="secondary"
                                                    data-testid="pass"
                                                >
                                                    {isPassVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="password"
                                        error={errors.password !== undefined && touched.password}
                                        type={isPassVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, password: undefined });
                                        }}
                                        value={values.password}
                                        placeholder="Enter password"
                                    />
                                    {errors.password && touched.password ? (
                                        <Typography variant="body2" color="secondary" height="40px">
                                            <ErrorRoundedIcon
                                                fontSize="small"
                                                color="inherit"
                                                sx={{ position: "relative", top: "6px", mr: "5px" }}
                                            />
                                            {errors.password}
                                        </Typography>
                                    ) : (
                                        <Typography width="19px" height="40px" />
                                    )}
                                </Box>
                            </Box>
                            <Stack direction="row" spacing="8px" alignItems="center" mb="27px">
                                <Checkbox onChange={handleChange} name="isRemembered" />
                                <Stack direction="row" width="100%" justifyContent="space-between">
                                    <Typography color="info.contrastText" fontSize="14px">
                                        Remember me
                                    </Typography>
                                </Stack>
                            </Stack>
                            <StyledButton
                                text="Login"
                                width="100%"
                                isAccept
                                isDisabled={
                                    !!errors.email ||
                                    !!errors.password ||
                                    !values.email ||
                                    !values.password
                                }
                                onHandleClick={() => onSubmitForm(values, errors)}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
        </>
    );
}
