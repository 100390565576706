import { api } from "api/api";
import { IGetNotificationsBody } from "./Notifications/Notifications.model";

export const getInfo = () => api.get("/onboarding/submissions/stats").then((res) => res);

// export const getNotifications = () => api.get("/onboarding/notifications").then((res) => res);

export const getNotifications = (body: IGetNotificationsBody = { pageNumber: 1, pageSize: 5 }) =>
    api.get("/onboarding/notifications", { params: { ...body } }).then((res) => res);

export const getSubmissions = (/* body = { query: "ars", pageNumber: 1, pageSize: 10 } */) =>
    api
        .get("/onboarding/submissions?pageSize=5000" /* , { params: { ...body } } */)
        .then((res) => res);
