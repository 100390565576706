import { Theme } from "@mui/material";

export default function Stepper(baseTheme: Theme) {
    return {
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    "& .MuiMobileStepper-dots": {
                        gap: "17px",
                        "& .MuiMobileStepper-dot": {
                            width: "12px",
                            height: "12px",
                        },
                        "& .MuiMobileStepper-dotActive": {
                            backgroundColor: baseTheme.palette.info.dark,
                        },
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: "24px",
                    width: "80%",
                    maxWidth: "1160px",
                    gap: "40px",
                    "& .MuiStepLabel-root": {
                        alignItems: "flex-start",
                    },
                    "& .MuiStepLabel-alternativeLabel": {
                        textAlign: "left",
                    },
                    "& .MuiStepConnector-root": {
                        left: "-90%",
                        right: "110%",
                        [baseTheme.breakpoints.between("xs", "lg")]: {
                            display: "none",
                        },
                    },
                    "& .MuiStep-vertical": {
                        "& .Mui-disabled": {
                            "& .MuiStepIcon-text": {
                                display: "none",
                            },
                            "& .MuiSvgIcon-root": {
                                fill: "transparent",
                                border: "1px solid",
                                borderColor: baseTheme.palette.info.dark,
                                borderRadius: "50%",
                            },
                        },
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fill: baseTheme.palette.secondary.main,
                    "& .MuiStepIcon-text": {
                        fontWeight: 900,
                        fontSize: "16px",
                        fill: baseTheme.palette.primary.light,
                    },
                    "&.Mui-active": {
                        fill: baseTheme.palette.primary.light,
                        borderRadius: "50%",
                        boxSizing: "border-box",
                        border: "1px solid",
                        borderColor: baseTheme.palette.secondary.main,
                    },
                },
            },
        },
    };
}
