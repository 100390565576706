import React from "react";
import { Box, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import login_back from "assets/Images/login_back.png";
import Page from "components/Page";
import { Header } from "./components/Header";
import { LoginForm } from "./components/LoginForm";

const useStyles = makeStyles((theme: Theme) => ({
    root: { backgroundColor: theme.palette.primary.dark },
    imageBox: {
        width: "50%",
        height: "100vh",
        backgroundImage: `url(${login_back})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    formBox: {
        margin: "auto",
        boxSizing: "border-box",
        [theme.breakpoints.down("lg")]: {
            padding: "50px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "10px",
        },
    },
}));

export function LoginPage() {
    const classes = useStyles();
    return (
        <Page title="Login | Staff Portal">
            <Stack sx={{ justifyContent: "center", alignItems: "center" }} className={classes.root}>
                <Stack width="100%" height="100vh" direction="row">
                    <Box data-testid="back_image" className={classes.imageBox} />
                    <Stack className={classes.formBox}>
                        <Header />
                        <LoginForm />
                    </Stack>
                </Stack>
            </Stack>
        </Page>
    );
}
