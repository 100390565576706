import React, { useState } from "react";
import { Typography, Stack, SvgIcon, Box } from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { ReactComponent as download } from "assets/Images/download2.svg";
import { KYCType } from "types/Models";
import { AccordionReviewFooter } from "./AccordionReviewFooter";

export function AccordionFileReview({ item }: { item: KYCType["documents"][0] }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const reviewColor = item.reviewStatus === "APPROVED" ? "success.dark" : "error.darker";
    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" onClick={() => setIsExpanded(!isExpanded)} spacing={1}>
                    <SvgIcon
                        component={isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.darker,
                            fontSize: "30px",
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            color:
                                item.reviewStatus === "APPROVED" ||
                                item.reviewStatus === "UPDATE_REQUESTED"
                                    ? reviewColor
                                    : ({ palette }) => palette.info.dark,
                        }}
                    >
                        {item.documentName}
                    </Typography>
                </Stack>
                <a href={item.documentURL} download>
                    <SvgIcon
                        component={download}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.main,
                            fontSize: "16px",
                            fill: "transparent",
                            stroke: ({ palette }) => palette.info.dark,
                        }}
                    />
                </a>
            </Stack>
            {isExpanded && (
                <Box p="10px 0px 20px 35px">
                    <AccordionReviewFooter status={item.reviewStatus} message={item?.message} />
                </Box>
            )}
        </Stack>
    );
}
