import React from "react";
import { Box, Typography, TextField, Theme } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { makeStyles } from "@mui/styles";

interface InputPropsType {
    errors: string | undefined;
    touched: boolean | undefined;
    handleBlur: any;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    value: string;
    label: string;
    name: string;
    placeholder: string;
    width: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        boxShadow: "0 0 0px 1000px white inset",
        color: "#000",
        borderColor: "grey",
    },
    outlinedInput: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.error.main} !important`,
        },
    },
}));

export function SimpleWhiteTextInput({
    errors,
    touched,
    handleBlur,
    handleChange,
    value,
    label,
    name,
    placeholder,
    width,
}: InputPropsType) {
    const classes = useStyles();
    return (
        <Box width={width} maxWidth="430px">
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    pb: "5px",
                }}
            >
                {label}
            </Typography>
            <TextField
                className={classes.outlinedInput}
                inputProps={{
                    className: classes.input,
                    style: {
                        fontSize: 16,
                        background: "white",
                        color: "#353F50",
                    },
                }}
                name={name}
                error={errors !== undefined && touched}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                autoComplete="new-password"
            />
            {touched && errors ? (
                <Typography variant="body2" sx={{ color: "red", height: "40px" }}>
                    <ErrorRoundedIcon
                        fontSize="small"
                        color="error"
                        sx={{ position: "relative", top: "6px", mr: "5px" }}
                    />
                    {errors}
                </Typography>
            ) : (
                <Typography
                    sx={{
                        height: "40px",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: ({ palette }) => palette.info.contrastText,
                        textAlign: "end",
                    }}
                />
            )}
        </Box>
    );
}
