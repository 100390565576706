import React, { useState } from "react";
import {
    Typography,
    Stack,
    SvgIcon,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { ReactComponent as download } from "assets/Images/download2.svg";
import { KYCType } from "types/Models";
import { Form, Formik } from "formik";

interface IAccordionPropsType {
    item: KYCType["documents"][0];
}

export function AccordionFile({ item }: IAccordionPropsType) {
    const [isExpanded, setIsExpanded] = useState(false);
    const reviewColor = item.reviewStatus === "APPROVED" ? "green" : "red";
    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" onClick={() => setIsExpanded(!isExpanded)} spacing={1}>
                    <SvgIcon
                        component={isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.dark,
                            fontSize: "30px",
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            color:
                                item.reviewStatus === "APPROVED" ||
                                item.reviewStatus === "UPDATE_REQUESTED"
                                    ? reviewColor
                                    : ({ palette }) => palette.info.dark,
                        }}
                    >
                        {item.documentName}
                    </Typography>
                </Stack>
                <a href={item.documentURL} download>
                    <SvgIcon
                        component={download}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.main,
                            fontSize: "16px",
                            fill: "transparent",
                            stroke: ({ palette }) => palette.info.dark,
                        }}
                    />
                </a>
            </Stack>
            {isExpanded && (
                <Formik
                    initialValues={{
                        status: item.reviewStatus || "",
                        message: item.message || "",
                    }}
                    onSubmit={() => console.log("here")}
                    enableReinitialize
                >
                    {({ values, handleChange }) => (
                        <Form
                            id="login"
                            data-testid="form"
                            autoComplete="off"
                            style={{ paddingLeft: "5px" }}
                        >
                            <RadioGroup
                                row
                                name="status"
                                defaultValue={item.reviewStatus}
                                value={values.status}
                                onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.value === "APPROVED") {
                                        // eslint-disable-next-line no-param-reassign
                                        item.message = "";
                                        // eslint-disable-next-line no-param-reassign
                                        values.message = "";
                                    }
                                    /* if (e.target.value === "UPDATE_REQUESTED") {
                                        setShowMessage(true);
                                    } else {
                                        // eslint-disable-next-line no-param-reassign
                                        value.message = "";
                                        setShowMessage(false);
                                    } */
                                    // eslint-disable-next-line no-param-reassign
                                    item.reviewStatus = e.target.value;
                                }}
                            >
                                <FormControlLabel
                                    value="APPROVED"
                                    control={<Radio />}
                                    label="Approve"
                                    sx={{
                                        "& .MuiTypography-root": {
                                            color: ({ palette }) => palette.primary.dark,
                                            fontWeight: 600,
                                            fontSize: "14px",
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value="UPDATE_REQUESTED"
                                    control={<Radio />}
                                    label="Request update"
                                    sx={{
                                        "& .MuiTypography-root": {
                                            color: ({ palette }) => palette.primary.dark,
                                            fontWeight: 600,
                                            fontSize: "14px",
                                        },
                                    }}
                                />
                            </RadioGroup>
                            {values.status === "UPDATE_REQUESTED" && (
                                <TextField
                                    autoComplete="new-password"
                                    sx={{
                                        mt: "30px",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ebebeb !important",
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            backgroundColor: "white",
                                            fontSize: 14,
                                            color: "#6D7E95",
                                        },
                                    }}
                                    name="message"
                                    onChange={(e) => {
                                        handleChange(e);
                                        // eslint-disable-next-line no-param-reassign
                                        item.message = e.target.value.trim();
                                    }}
                                    value={values.message}
                                    placeholder="Enter reason for rejection"
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </Stack>
    );
}
