import React from "react";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { routePaths } from "routes/routePaths";
import { StyledButton } from "components/StyledButton";
import { SimpleVerticalStepper } from "./SimpleVerticalStepper";
import { SectionHeader } from "./SectionHeader";
import { useData } from "../SubmissionReviewPage";
import { APIContent } from "./APIContent";
import { DevConsoleSection } from "./DevConsoleContent";
import { PersonalizationFiles } from "./PersonalizationFiles";
import { PersonalizationText } from "./PersonalizationText";
import { BusinessInfoContent } from "./BusinessInfoContent";
import { KYCApproval } from "./KYCApproval";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "75%",
        maxWidth: "900px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    inner: {
        backgroundColor: theme.palette.common.white,
        padding: "40px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "7px",
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
    },
}));

export function Approval() {
    const data = useData()?.submissionData;
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <>
            <SimpleVerticalStepper step={6} />
            <Stack className={classes.container}>
                <Stack className={classes.inner}>
                    {data ? (
                        <Stack spacing={7}>
                            <Box>
                                <SectionHeader
                                    name="Business Information"
                                    needStatus
                                    status={data?.profile?.reviewStatus}
                                />
                                <BusinessInfoContent data={data?.profile} />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="Personalisation"
                                    needStatus
                                    status={data?.personalization?.reviewStatus}
                                />
                                <Stack spacing={3}>
                                    <PersonalizationText data={data?.personalization} />
                                    <PersonalizationFiles data={data?.personalization} />
                                </Stack>
                            </Box>
                            <Box>
                                <SectionHeader name="KYC Information" needStatus={false} />
                                <KYCApproval data={data} />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="API Documentation"
                                    needStatus
                                    status={data?.settlements?.reviewStatus}
                                />
                                <APIContent data={data?.settlements} />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="Interswitch Developer Console"
                                    needStatus
                                    status={data?.iswPortal?.iswPortalReviewStatus}
                                />
                                <DevConsoleSection data={data?.iswPortal} />
                            </Box>
                        </Stack>
                    ) : (
                        <Typography variant="body1" color="error.darker" fontStyle="italic">
                            No information to display
                        </Typography>
                    )}
                </Stack>
                <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                    <StyledButton
                        text="Submit"
                        width="197px"
                        isAccept
                        isDisabled={false}
                        onHandleClick={() => navigate(routePaths.dashboard)}
                    />
                </Stack>
            </Stack>
        </>
    );
}
