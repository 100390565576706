export default function Dialog() {
    return {
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& 	.MuiDialog-paper": {
                        maxWidth: "870px",
                    },
                },
            },
        },
    };
}
