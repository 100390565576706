import React from "react";
import { Stack, Avatar, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { trimName } from "helpers";
import { IStatuses } from "types/Models";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: "40px 90px",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    bankName: {
        color: theme.palette.grey[800],
        fontWeight: 600,
        fontSize: "32px",
    },
    bankId: {
        color: theme.palette.grey[300],
        fontWeight: 400,
        fontSize: "20px",
    },
}));

export function Heading({ statuses }: { statuses: IStatuses | undefined }) {
    const classes = useStyles();
    const bankName: string | undefined = statuses?.name;
    const institutionId: number | undefined = statuses?.id;
    return (
        <Stack className={classes.container}>
            <Stack direction="row" sx={{ alignItems: "center", gap: "40px" }}>
                <Avatar
                    data-testid="avatar"
                    sx={{
                        width: "80px",
                        height: "80px",
                        bgcolor: ({ palette }) => palette.common.white,
                        color: ({ palette }) => palette.primary.main,
                        fontWeight: 400,
                        fontSize: "32px",
                    }}
                >
                    {bankName ? trimName(bankName) : trimName("Bank")}
                </Avatar>
                <Stack>
                    <Typography className={classes.bankName}>{bankName || "Bank"}</Typography>
                    {institutionId && (
                        <Typography className={classes.bankId}>BankID: {institutionId}</Typography>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}
