import React, { useRef, useState } from "react";
import { AlertColor, Box, Stack, Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ISubmission } from "api/apiExample";
import { ModalCell } from "pages/Submissions/SubmissionMenu";
import { ISubmissionRow } from "types/Models";
import { ReviewCell } from "./components/ReviewCell";
import { SubmissionLabel } from "./components/SubmissionLabel";
import { DateCell } from "./components/DataCell";

interface ISubmissionsProps {
    submissions: ISubmission[];
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        height: "100%",
        backgroundColor: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.secondary.contrastText,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px",
        borderBottom: `2px solid ${theme.palette.secondary.contrastText}`,
        borderColor: theme.palette.secondary.contrastText,
    },
}));

export function SubmissionsSection({
    submissions,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setTableUpdate,
    needTableUpdate,
}: ISubmissionsProps) {
    const gridRef = useRef<AgGridReact<ISubmission>>(null);
    const classes = useStyles();

    const [columnDefs] = useState([
        { headerName: "", field: "", flex: 1, minWidth: 50, maxWidth: 50, suppressMovable: true },
        {
            headerName: "Bank ID",
            field: "id",
            flex: 1,
            minWidth: 100,
            suppressMovable: true,
            cellRenderer: ({ data }: ISubmissionRow) => `#${data.id}`,
        },
        { headerName: "Bank Name ", field: "name", flex: 2, minWidth: 250, suppressMovable: true },
        {
            headerName: "Date Created",
            field: "createdAt",
            cellRenderer: DateCell,
            flex: 2,
            minWidth: 200,
            suppressMovable: true,
        },
        {
            headerName: "Submission Status",
            field: "submissionStatus",
            cellRenderer: SubmissionLabel,
            flex: 2,
            minWidth: 200,
            suppressMovable: true,
        },
        {
            headerName: "Review Status",
            cellRenderer: ReviewCell,
            flex: 2,
            minWidth: 200,
            suppressMovable: true,
        },
        {
            headerName: "",
            field: "",
            cellRenderer: ModalCell,
            cellRendererParams: {
                setLoaderOpen,
                setSnackType,
                setSnackMessage,
                setSnackOpen,
                needTableUpdate,
                setTableUpdate,
            },
            flex: 1,
            minWidth: 100,
            suppressMovable: true,
        },
    ]);

    return (
        <Stack className={classes.section}>
            <Stack className={classes.header}>
                <Typography variant="button" color="common.black">
                    Submissions
                </Typography>
                <Typography
                    variant="button"
                    color="primary.main"
                    component={Link}
                    to="/submissions"
                >
                    View All
                </Typography>
            </Stack>
            <Box className="ag-theme-alpine" style={{ height: "100%" }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={submissions.slice(0, 4)}
                />
            </Box>
        </Stack>
    );
}
