export const routePaths = {
    login: "/login",
    dashboard: "/dashboard",
    submissions: "/submissions",
    test: "/test",
    review: "/review",
    personalization_review: "/review/personalization",
    kyc_review: "/review/kyc",
    api_review: "/review/api",
    dev_console_review: "/review/dev-console",
    approval_review: "/review/approval",
    notifications: "/notifications",
    user_management: "/user-management",
    create_user: "/user-management/create",
};
