import React from "react";
import { Box, SvgIcon } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type ModalPropsType = {
    handleClose: () => void;
};

export function CloseBtn({ handleClose }: ModalPropsType) {
    return (
        <Box
            width="32px"
            height="32px"
            onClick={handleClose}
            sx={{
                backgroundColor: ({ palette }) => palette.secondary.light,
                borderRadius: "50%",
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                    backgroundColor: ({ palette }) => palette.grey[100],
                },
            }}
        >
            <SvgIcon
                component={CloseRoundedIcon}
                inheritViewBox
                sx={{
                    m: "5px",
                    fontSize: "22px",
                    color: ({ palette }) => palette.grey[300],
                }}
            />
        </Box>
    );
}
