/* eslint-disable react/destructuring-assignment */
import React, { useRef } from "react";
import {
    Box,
    Button,
    Dialog,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    Theme,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ISubmission } from "api/apiExample";
import { ReactComponent as FilterIcon } from "assets/Images/filter.svg";
import { AgGridReact } from "ag-grid-react";
import { StyledButton } from "components/StyledButton";
import { ISearchSubmissions } from "types/Models";
import { makeStyles } from "@mui/styles";

interface IFilterProps {
    setFilteredSubmissions: (value: ISubmission[] | undefined) => void;
    setIsFiltered: (value: boolean) => void;
    setFilterMenuOpen: (value: boolean) => void;
    setFormValues: ({
        reviewStatus,
        submissionStatus,
    }: {
        reviewStatus: string;
        submissionStatus: string;
    }) => void;
    goToFirstPage: () => void;
    formValues: { reviewStatus: string; submissionStatus: string };
    isFilterMenuOpen: boolean;
    submissions: ISubmission[];
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "60vw",
        maxWidth: "600px",
        padding: "50px",
        gap: "50px",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            gap: "30px",
        },
    },
    fields: {
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "15px",
        },
    },
    field: {
        width: "40%",
        gap: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    buttons: {
        flexDirection: "row",
        justifyContent: "center",
        gap: "30px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "15px",
        },
    },
    button: {
        width: "40%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const reviewStatuses = [
    { id: 1, name: "Approved", code: "APPROVED" },
    { id: 2, name: "Rejected", code: "REJECTED" },
    { id: 3, name: "Review in progress", code: "REVIEW_IN_PROGRESS" },
    { id: 4, name: "Pending review", code: "PENDING_REVIEW" },
    { id: 5, name: "Update requested", code: "UPDATE_REQUESTED" },
];

const submissionStatuses = [
    { id: 1, name: "Incomplete", code: "INCOMPLETE" },
    { id: 2, name: "Submitted", code: "SUBMITTED" },
];

export const validationSchema = yup.object({
    reviewStatus: yup.string(),
    submissionStatus: yup.string(),
});

export function Filter({
    setFilteredSubmissions,
    setIsFiltered,
    setFilterMenuOpen,
    setFormValues,
    formValues,
    isFilterMenuOpen,
    submissions,
    goToFirstPage,
}: IFilterProps) {
    const classes = useStyles();
    const gridRef = useRef<AgGridReact<ISubmission>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);

    return (
        <Stack sx={{ height: "auto" }}>
            <Formik<ISearchSubmissions>
                initialValues={formValues}
                onSubmit={() => console.log("here")}
                validateOnBlur
                validationSchema={validationSchema}
            >
                {({ values, handleChange }) => (
                    <Form style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    sx={{ cursor: "pointer", alignItems: "center" }}
                                    onClick={() => {
                                        setFilterMenuOpen(true);
                                    }}
                                >
                                    <SvgIcon component={FilterIcon} viewBox="0 0 24 14" />
                                    Filter
                                </Stack>
                            </Grid>
                            <Dialog open={isFilterMenuOpen}>
                                <Stack className={classes.container}>
                                    <Stack className={classes.fields}>
                                        <Stack className={classes.field}>
                                            <InputLabel
                                                sx={{ fontSize: "14px", color: "primary.main" }}
                                            >
                                                Select Review status
                                            </InputLabel>
                                            <Select
                                                name="reviewStatus"
                                                value={values.reviewStatus}
                                                onChange={handleChange}
                                                // pay attention here! delay in values.reviewStatus
                                                sx={{
                                                    background: ({ palette }) =>
                                                        palette.common.white,
                                                    "& .MuiInputBase-input": {
                                                        color: ({ palette }) =>
                                                            palette.text.primary,
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                    },
                                                    width: "100%",
                                                }}
                                                inputProps={{
                                                    style: { color: "text.primary" },
                                                }}
                                            >
                                                <MenuItem value="All">All</MenuItem>
                                                {reviewStatuses.map((status) => (
                                                    <MenuItem key={status.id} value={status.code}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                        <Stack className={classes.field}>
                                            <InputLabel
                                                sx={{ fontSize: "14px", color: "primary.main" }}
                                            >
                                                Select Submission status
                                            </InputLabel>
                                            <Select
                                                name="submissionStatus"
                                                value={values.submissionStatus}
                                                onChange={handleChange}
                                                // pay attention here! delay in values.submissionStatus
                                                sx={{
                                                    background: ({ palette }) =>
                                                        palette.common.white,
                                                    "& .MuiInputBase-input": {
                                                        color: ({ palette }) =>
                                                            palette.text.primary,
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                    },
                                                    width: "100%",
                                                }}
                                                inputProps={{
                                                    style: { color: "text.primary" },
                                                }}
                                            >
                                                <MenuItem value="All">All</MenuItem>
                                                {submissionStatuses.map((status) => (
                                                    <MenuItem key={status.id} value={status.code}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </Stack>

                                    <Stack className={classes.buttons}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setFilterMenuOpen(false)}
                                            className={classes.button}
                                        >
                                            Cancel
                                        </Button>
                                        <Box className={classes.button}>
                                            <StyledButton
                                                width="100%"
                                                isAccept
                                                text="Filter"
                                                onHandleClick={() => {
                                                    setFilterMenuOpen(false);
                                                    setFormValues(values);
                                                    setFilteredSubmissions(
                                                        submissions.filter(
                                                            (elem: ISubmission): boolean => {
                                                                if (
                                                                    values.reviewStatus &&
                                                                    values.submissionStatus
                                                                ) {
                                                                    return (
                                                                        (values.reviewStatus ===
                                                                        "All"
                                                                            ? true
                                                                            : elem.reviewStatus ===
                                                                              values.reviewStatus) &&
                                                                        (values.submissionStatus ===
                                                                        "All"
                                                                            ? true
                                                                            : elem.submissionStatus ===
                                                                              values.submissionStatus)
                                                                    );
                                                                }
                                                                if (values.reviewStatus) {
                                                                    return values.reviewStatus ===
                                                                        "All"
                                                                        ? true
                                                                        : elem.reviewStatus ===
                                                                              values.reviewStatus;
                                                                }
                                                                if (values.submissionStatus) {
                                                                    return values.submissionStatus ===
                                                                        "All"
                                                                        ? true
                                                                        : elem.submissionStatus ===
                                                                              values.submissionStatus;
                                                                }
                                                                return false;
                                                            },
                                                        ),
                                                    );
                                                    setIsFiltered(
                                                        !!values.reviewStatus ||
                                                            !!values.submissionStatus,
                                                    );
                                                    if (gridRef.current && paginationRef.current) {
                                                        gridRef.current.api.paginationGoToFirstPage();
                                                    }
                                                    goToFirstPage();
                                                }}
                                                isDisabled={false}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Dialog>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Stack>
    );
}
