import React from "react";
import { Grid, Stack, Theme, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import { INotification } from "types/Models";
import { makeStyles } from "@mui/styles";
import { formatDate } from "helpers";

interface INotificationProps {
    item: INotification;
    setNotification: (value: INotification | undefined) => void;
    setModalOpen: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    secondColumn: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    container: {
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.07)",
        background: theme.palette.common.white,
        "&:hover": {
            background: theme.palette.primary.lighter,
            "& .bankId": {
                color: theme.palette.primary.main,
            },
            "& .subject": {
                fontWeight: 800,
            },
            "& .logId": {
                color: theme.palette.secondary.dark,
            },
        },
    },
}));

export function Notification({ item, setNotification, setModalOpen }: INotificationProps) {
    const classes = useStyles();
    const onNotificationView = (notif: INotification | undefined) => {
        setNotification(notif);
        setModalOpen(true);
    };
    return (
        <Grid container className={classes.container}>
            <Grid item xs={8} sm={5}>
                <Stack spacing={1} padding="10px 22px">
                    <Typography variant="button" className="subject" color="text.disabled">
                        {item.subject}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" className="bankId">
                        Bank ID: {item.institutionId}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item sm={5} className={classes.secondColumn}>
                <Stack spacing={1} padding="10px 22px">
                    <Typography variant="button" color="text.disabled" className="logId">
                        Log ID: {item.id}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        {formatDate(item.sentTime)}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2}>
                <Stack height="70px">
                    <Typography
                        variant="button"
                        color="text.disabled"
                        margin="auto"
                        padding="10px 20px"
                        onClick={() => onNotificationView(item)}
                        sx={{ cursor: "pointer" }}
                    >
                        View
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}
