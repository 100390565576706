import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { NavBar } from "./NavBar";
import { Logo } from "./Logo";

export function SideBar() {
    return (
        <Stack
            sx={{
                bgcolor: "primary.dark",
                justifyContent: "space-between",
                minHeight: "100vh",
                height: "100%",
            }}
        >
            <Box width="270px">
                <Box padding="30px 0 30px 30px">
                    <Logo width="150px" height="50px" />
                </Box>
                <NavBar />
            </Box>
            <Typography fontSize="14px" padding="0 30px 40px">
                © 2002 - {new Date().getFullYear()} Interswitch Group
            </Typography>
        </Stack>
    );
}
