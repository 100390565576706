import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StyledButton } from "components/StyledButton";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { rolesList } from "helpers";

interface ISubmProps {
    setModalOpen: (value: boolean) => void;
    isModalOpen: boolean;
    agreeToEdit: (values: { role: string }) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "40px 80px",
        alignItems: "center",
        gap: "40px",
    },
    buttons: {
        flexDirection: "row",
        gap: "35px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    modal: {
        width: "40vw",
        minHeight: "40vh",
        padding: "40px 50px",
        boxSizing: "border-box",
        justifyContent: "space-between",
        gap: "20px",
        [theme.breakpoints.down("md")]: {
            padding: "20px",
            width: "80vw",
            minHeight: "40vh",
        },
        [theme.breakpoints.down("sm")]: {
            width: "80vw",
            minHeight: "60vh",
        },
    },
    select: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.error.main} !important`,
        },
    },
    input: {
        boxShadow: "0 0 0px 1000px white inset",
        color: `${theme.palette.grey[800]} !important`,
    },
}));

export function EditUserModal({ isModalOpen, setModalOpen, agreeToEdit }: ISubmProps) {
    const classes = useStyles();
    const [role, setRole] = useState<string>("");

    return (
        <Dialog open={isModalOpen}>
            <Stack className={classes.container}>
                <Typography
                    variant="h3"
                    sx={{
                        alignSelf: "flex-start",
                        color: ({ palette }) => palette.grey[200],
                    }}
                >
                    Edit User’s Role
                </Typography>
                <Box
                    sx={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: ({ palette }) => palette.grey[200],
                    }}
                />
                <Box width="100%">
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "12px",
                            pb: "5px",
                            color: ({ palette }) => palette.grey[800],
                        }}
                    >
                        Role
                    </Typography>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: ({ palette }) => palette.info.main,
                            borderRadius: "4px",
                        }}
                    >
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    display: "none",
                                },
                                "&.MuiFormLabel-filled": {
                                    display: "none",
                                },
                            }}
                        >
                            Select user&apos;s role
                        </InputLabel>
                        <Select
                            IconComponent={ExpandMoreRoundedIcon}
                            name="roleId"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className={classes.select}
                            inputProps={{
                                className: classes.input,
                            }}
                        >
                            {rolesList.map((item) => (
                                <MenuItem key={item.id} value={item.code}>
                                    {item.descr}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Stack className={classes.buttons}>
                    <Button
                        variant="outlined"
                        onClick={() => setModalOpen(false)}
                        sx={{
                            borderColor: ({ palette }) => palette.text.secondary,
                            color: ({ palette }) => palette.text.secondary,
                        }}
                    >
                        Cancel
                    </Button>
                    <StyledButton
                        width="197px"
                        text="Save"
                        isAccept
                        isDisabled={!role}
                        onHandleClick={() => agreeToEdit({ role })}
                    />
                </Stack>
            </Stack>
        </Dialog>
    );
}
