import React from "react";
import {
    alpha,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

export interface ISidebarItem {
    id: string | number;
    title: string;
    path: string;
    icon: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.text.disabled,
        "& .MuiSvgIcon-root": { fill: theme.palette.text.disabled },
    },
    activeItem: {
        color: theme.palette.primary.light,
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
        "& .MuiSvgIcon-root": { fill: theme.palette.primary.light },
    },
}));

interface INavItemProps {
    item: ISidebarItem;
    active: (itemPath: string) => boolean;
}

export function NavItem({ item, active }: INavItemProps) {
    const classes = useStyles();
    const isActiveRoot = active(item.path);
    const { title, path, icon } = item;

    return (
        <ListItemButton
            component={Link}
            to={path}
            className={isActiveRoot ? classes.activeItem : classes.root}
        >
            <ListItemIcon
                sx={{
                    stroke: isActiveRoot
                        ? ({ palette }) => palette.primary.light
                        : ({ palette }) => palette.text.disabled,
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText primary={<Typography variant="h4">{title}</Typography>} />
        </ListItemButton>
    );
}
