import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { InfoItem } from "components/InfoItem";
import { BusinessType } from "types/Models";

export function BusinessInfoContent({ data }: { data: BusinessType }) {
    return data ? (
        <Stack spacing={5}>
            {(data.name || data.rcNo || data.phoneNo) && (
                <Grid container>
                    {data.name && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Business Name" value={data.name} />
                        </Grid>
                    )}
                    {data.rcNo && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Business Registration Number" value={data.rcNo} />
                        </Grid>
                    )}
                    {data.phoneNo && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Official Phone Number" value={data.phoneNo} />
                        </Grid>
                    )}
                </Grid>
            )}
            {(data.addressLine1 || data.addressLine2 || data.email) && (
                <Grid container spacing={2}>
                    {data.addressLine1 && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Head Office Address" value={data.addressLine1} />
                        </Grid>
                    )}
                    {data.addressLine2 && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Head Office Address 2" value={data.addressLine2} />
                        </Grid>
                    )}
                    {data.email && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Contact Email Address" value={data.email} />
                        </Grid>
                    )}
                </Grid>
            )}
            {(data.state || data.zipCode) && (
                <Grid container spacing={2}>
                    {data.state && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="State" value={data.state} />
                        </Grid>
                    )}
                    {data.zipCode && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Zip Code" value={data.zipCode} />
                        </Grid>
                    )}
                </Grid>
            )}
            {(data.contactName || data.contactEmail || data.contactPhoneNo) && (
                <>
                    <Typography variant="body2" sx={{ color: ({ palette }) => palette.grey[200] }}>
                        Contact Information
                    </Typography>
                    <Grid container spacing={2}>
                        {data.contactName && (
                            <Grid item xs={12} md={4}>
                                <InfoItem name="Primary Contact Name" value={data.contactName} />
                            </Grid>
                        )}
                        {data.contactEmail && (
                            <Grid item xs={12} md={4}>
                                <InfoItem name="Contact Email Address" value={data.contactEmail} />
                            </Grid>
                        )}
                        {data.contactPhoneNo && (
                            <Grid item xs={12} md={4}>
                                <InfoItem name="Contact Phone Number" value={data.contactPhoneNo} />
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Stack>
    ) : (
        <Typography variant="body1" color="error.darker" fontStyle="italic">
            No information to display. Please try again
        </Typography>
    );
}
