import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SettlementsType } from "types/Models";
import { APITextPart } from "./APITextPart";
import { File } from "./File";

export function APIContent({ data }: { data: SettlementsType }) {
    return (
        <>
            {data?.apiDocName && (
                <Box mb="30px">
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            color: ({ palette }) => palette.grey[300],
                            pb: "10px",
                        }}
                    >
                        API Document
                    </Typography>
                    <File fileName={data?.apiDocName} fileURL={data?.apiDocURL} />
                </Box>
            )}
            <Stack gap="20px">
                <Stack spacing={2}>
                    <APITextPart data={data} />
                    {data?.settlementLetterName && (
                        <Box mb="30px">
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: ({ palette }) => palette.grey[300],
                                    pb: "10px",
                                }}
                            >
                                Letter of Authorization
                            </Typography>
                            <File
                                fileName={data?.settlementLetterName}
                                fileURL={data?.settlementLetterURL}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>
        </>
    );
}
