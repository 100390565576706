import React from "react";
import { Box, Button, Dialog, Stack, SvgIcon, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { StyledButton } from "components/StyledButton";

interface IModalProps {
    type: string;
    isModalOpen: boolean;
    userEmail: string;
    setModalOpen: (value: boolean) => void;
    handleClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "40px 80px",
        alignItems: "center",
        gap: "12px",
    },
    buttons: {
        flexDirection: "row",
        gap: "35px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    modal: {
        width: "40vw",
        minHeight: "40vh",
        padding: "40px 50px",
        boxSizing: "border-box",
        justifyContent: "space-between",
        gap: "20px",
        [theme.breakpoints.down("md")]: {
            padding: "20px",
            width: "80vw",
            minHeight: "40vh",
        },
        [theme.breakpoints.down("sm")]: {
            width: "80vw",
            minHeight: "60vh",
        },
    },
}));

export function Modal({ type, isModalOpen, userEmail, setModalOpen, handleClick }: IModalProps) {
    const classes = useStyles();
    return (
        <Dialog open={isModalOpen}>
            <Stack className={classes.container}>
                <Box
                    sx={{
                        width: "96px",
                        height: "96px",
                        backgroundColor:
                            type === "success"
                                ? ({ palette }) => palette.primary.light
                                : ({ palette }) => palette.error.light,
                        borderRadius: "50%",
                    }}
                >
                    <SvgIcon
                        component={type === "success" ? DoneRoundedIcon : PriorityHighRoundedIcon}
                        inheritViewBox
                        sx={{
                            mt: "24px",
                            ml: "24px",
                            fontSize: "50px",
                            color: ({ palette }) => palette.common.white,
                        }}
                    />
                </Box>
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontWeight: "600",
                        maxWidth: "250px",
                        color: ({ palette }) => palette.grey[800],
                        textAlign: "center",
                    }}
                >
                    {type === "success"
                        ? "User Created successfully"
                        : "Are you sure you want to delete this user?"}
                </Typography>
                <Box mb="30px">
                    <Typography
                        variant="body1"
                        maxWidth="350px"
                        color="info.dark"
                        textAlign="center"
                    >
                        {type === "success"
                            ? "An email notification has been sent to the user"
                            : "This user will no longer have access to the portal"}
                    </Typography>
                    {type === "success" && (
                        <Typography variant="h4" color="info.dark" textAlign="center">
                            {userEmail}
                        </Typography>
                    )}
                </Box>
                <Stack className={classes.buttons}>
                    {type !== "success" && (
                        <Button
                            variant="outlined"
                            onClick={() => setModalOpen(false)}
                            sx={{
                                borderColor: ({ palette }) => palette.text.secondary,
                                color: ({ palette }) => palette.text.secondary,
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                    <StyledButton
                        width="197px"
                        isAccept={type === "success"}
                        text={type === "success" ? "Got it" : "Delete"}
                        isDisabled={false}
                        onHandleClick={handleClick}
                    />
                </Stack>
            </Stack>
        </Dialog>
    );
}
