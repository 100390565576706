import React, { useState } from "react";
import {
    AlertColor,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { LinkMoveBack } from "components/LinkMoveBack";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { routePaths } from "routes/routePaths";
import { Form, Formik } from "formik";
import { SimpleWhiteTextInput } from "components/SimpleWhiteTextInput";
import { rolesList } from "helpers";
import { StyledButton } from "components/StyledButton";
import { useNavigate } from "react-router-dom";
import { api } from "api/api";
import { Modal } from "./components/Modal";

interface ISubmProps {
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
}

type DataType = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px",
        borderBottom: `2px solid ${theme.palette.secondary.contrastText}`,
        borderColor: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.common.white,
    },
    input: {
        boxShadow: "0 0 0px 1000px white inset",
        color: `${theme.palette.grey[800]} !important`,
    },
    select: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.error.main} !important`,
        },
    },
}));

export const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("This field is required"),
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    role: yup.string().required("This field is required"),
});

export function CreateUser({
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
}: ISubmProps) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [userEmail, setUserEmail] = useState("to the email address you provided");

    const onSubmitForm = (values: DataType) => {
        setLoaderOpen(true);
        api.post("/users/admins/create", { ...values })
            .then(() => {
                setUserEmail(values.email);
                setModalOpen(true);
            })
            .catch(() => {
                setSnackType("error");
                setSnackMessage("Failed to create the user");
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    };

    const handleClose = () => {
        setModalOpen(false);
        navigate(routePaths.user_management);
    };

    return (
        <Stack>
            <LinkMoveBack
                url={routePaths.user_management}
                text="User Management"
                padding="40px 30px"
                back="white"
            />
            <Stack height="100%" margin="30px" spacing={4}>
                <Typography
                    variant="subtitle2"
                    sx={{ color: ({ palette }) => palette.grey[800], fontWeight: 600 }}
                >
                    Create new user
                </Typography>
                <Formik<DataType>
                    enableReinitialize
                    initialValues={{ email: "", firstName: "", lastName: "", role: "" }}
                    onSubmit={() => console.log("here")}
                    validateOnMount
                    validationSchema={validationSchema}
                >
                    {({ values, errors, handleBlur, touched, handleChange, isValid }) => (
                        <Form key="business-info-form" id="business_info" data-testid="form">
                            <Stack flexGrow={1}>
                                <Box
                                    sx={{
                                        bgcolor: ({ palette }) => palette.common.white,
                                        p: "30px 40px 30px 40px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                                    }}
                                >
                                    <Stack>
                                        <SimpleWhiteTextInput
                                            errors={errors.email}
                                            touched={touched.email}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.email}
                                            label="Email Address"
                                            name="email"
                                            placeholder="Enter user's email address"
                                            width="100%"
                                        />
                                        <SimpleWhiteTextInput
                                            errors={errors.firstName}
                                            touched={touched.firstName}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.firstName}
                                            label="First Name"
                                            name="firstName"
                                            placeholder="Enter user's first name"
                                            width="100%"
                                        />
                                        <SimpleWhiteTextInput
                                            errors={errors.lastName}
                                            touched={touched.lastName}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.lastName}
                                            label="Last Name"
                                            name="lastName"
                                            placeholder="Enter user's last name"
                                            width="100%"
                                        />
                                        <Box width="100%" maxWidth="430px">
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: "12px",
                                                    pb: "5px",
                                                    color: ({ palette }) => palette.text.primary,
                                                }}
                                            >
                                                Role
                                            </Typography>
                                            <FormControl
                                                fullWidth
                                                sx={{
                                                    backgroundColor: ({ palette }) =>
                                                        palette.info.main,
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <InputLabel
                                                    sx={{
                                                        "&.Mui-focused": {
                                                            display: "none",
                                                        },
                                                        "&.MuiFormLabel-filled": {
                                                            display: "none",
                                                        },
                                                    }}
                                                >
                                                    Select user&apos;s role
                                                </InputLabel>
                                                <Select
                                                    IconComponent={ExpandMoreRoundedIcon}
                                                    name="role"
                                                    value={values.role}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={classes.select}
                                                    error={
                                                        errors.role !== undefined && touched.role
                                                    }
                                                    inputProps={{
                                                        className: classes.input,
                                                    }}
                                                >
                                                    {rolesList.map((item) => (
                                                        <MenuItem key={item.id} value={item.code}>
                                                            {item.descr}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {touched.role && errors.role ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "red",
                                                        height: "40px",
                                                    }}
                                                >
                                                    <ErrorRoundedIcon
                                                        fontSize="small"
                                                        color="error"
                                                        sx={{
                                                            position: "relative",
                                                            top: "6px",
                                                            mr: "5px",
                                                        }}
                                                    />
                                                    {errors.role}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        height: "40px",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: ({ palette }) =>
                                                            palette.info.contrastText,
                                                        textAlign: "end",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                    <StyledButton
                                        text="Create new user"
                                        width="430px"
                                        isAccept
                                        isDisabled={!isValid}
                                        onHandleClick={() => onSubmitForm(values)}
                                    />
                                </Box>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>
            <Modal
                type="success"
                isModalOpen={isModalOpen}
                userEmail={userEmail}
                handleClick={handleClose}
                setModalOpen={setModalOpen}
            />
        </Stack>
    );
}
