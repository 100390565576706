import React from "react";
import { Stack } from "@mui/material";
import { ReactComponent as Check } from "assets/Images/check.svg";
import { ReactComponent as Wait } from "assets/Images/wait.svg";
import { ReactComponent as Cancel } from "assets/Images/cancel.svg";
import { IStatsInfo } from "types/Models";
import { InfoItem } from "./InfoItem";

interface IInfoProps {
    // eslint-disable-next-line react/require-default-props
    info?: IStatsInfo | undefined;
}

const infoConfig = (info: IStatsInfo | undefined) => [
    {
        id: 1,
        title: " Registered Banks",
        value: info ? info.total : "",
        icon: <Check />,
        color: "success.lighter",
    },
    {
        id: 2,
        title: "Pending Approval",
        value: info ? info.completed : "",
        icon: <Wait />,
        color: "warning.contrastText",
    },
    {
        id: 3,
        title: " Incompleted Registration",
        value: info ? info.inComplete : "",
        icon: <Cancel />,
        color: "error.lighter",
    },
];

export function InfoSection({ info }: IInfoProps) {
    return (
        <Stack spacing={1}>
            {infoConfig(info).map((item) => (
                <InfoItem key={item.id} item={item} />
            ))}
        </Stack>
    );
}
