import React, { useState } from "react";
import { Box, Drawer, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SideBar } from "components/SideBar";
import { Header } from "components/Header";

interface IFrameProps {
    children: JSX.Element;
    // eslint-disable-next-line react/require-default-props
    notificationsCount: number;
    color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
    },
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

export function Frame({ children, notificationsCount, color }: IFrameProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <Stack className={classes.root}>
            <Stack width="100%" height="100%" direction="row">
                <Drawer open={open} onClose={() => setOpen(false)}>
                    <SideBar />
                </Drawer>
                <Box className={classes.sideMenu}>
                    <SideBar />
                </Box>
                <Stack width="100%">
                    <Header
                        onOpenSidebar={() => setOpen(true)}
                        notificationsCount={notificationsCount}
                        color={color}
                    />
                    {children}
                </Stack>
            </Stack>
        </Stack>
    );
}
