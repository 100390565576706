import React from "react";
import { Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { routePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { SectionHeader } from "./SectionHeader";
import { SimpleVerticalStepper } from "./SimpleVerticalStepper";
import { useData } from "../SubmissionReviewPage";
import { SectionFooter } from "./SectionFooter";
import { DevConsoleSection } from "./DevConsoleContent";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "75%",
        maxWidth: "900px",
        marginBottom: "88px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    inner: {
        backgroundColor: theme.palette.common.white,
        padding: "40px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "7px",
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
    },
}));

export function DevConsole() {
    const data = useData()?.submissionData?.iswPortal;
    const navigate = useNavigate();
    const classes = useStyles();
    const onBack = () => navigate(routePaths.api_review);
    return (
        <>
            <SimpleVerticalStepper step={5} />
            <Stack className={classes.container}>
                <Stack className={classes.inner}>
                    <SectionHeader name="Interswitch Developer Console" needStatus={false} />
                    <DevConsoleSection data={data} />
                    <SectionFooter
                        formValues={data}
                        url="onboarding/business/dev-portal/credentials/review"
                        nextStep={routePaths.approval_review}
                        onBack={onBack}
                    />
                </Stack>
            </Stack>
        </>
    );
}
