import { Theme } from "@mui/material";

export default function Tooltip(baseTheme: Theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    width: "195px",
                    color: baseTheme.palette.info.contrastText,
                    backgroundColor: baseTheme.palette.background.default,
                    fontWeight: 400,
                    fontSize: "12px",
                    border: "px solid",
                    borderColor: baseTheme.palette.secondary.contrastText,
                    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
                },
                popper: {
                    borderRadius: "11px",
                },
            },
        },
    };
}
