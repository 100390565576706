import { Theme } from "@mui/material";

export default function Checkbox(baseTheme: Theme) {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    color: baseTheme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    };
}
