import React from "react";
import { Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { routePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { SectionHeader } from "./SectionHeader";
import { SimpleVerticalStepper } from "./SimpleVerticalStepper";
import { useData } from "../SubmissionReviewPage";
import { SectionFooter } from "./SectionFooter";
import { APIContent } from "./APIContent";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "75%",
        maxWidth: "900px",
        marginBottom: "88px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    inner: {
        backgroundColor: theme.palette.common.white,
        padding: "40px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "7px",
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
    },
}));

export function API() {
    const data = useData()?.submissionData?.settlements;
    const navigate = useNavigate();
    const classes = useStyles();
    const onBack = () => navigate(routePaths.kyc_review);
    return (
        <>
            <SimpleVerticalStepper step={4} />
            <Stack className={classes.container}>
                <Stack className={classes.inner}>
                    <SectionHeader name="API Documentation" needStatus={false} />
                    {data ? (
                        <APIContent data={data} />
                    ) : (
                        <Typography variant="body1" color="error.dark" fontStyle="italic">
                            No information to display. Please try again
                        </Typography>
                    )}
                    <SectionFooter
                        formValues={data}
                        url="/onboarding/settlements/review"
                        nextStep={routePaths.dev_console_review}
                        onBack={onBack}
                    />
                </Stack>
            </Stack>
        </>
    );
}
