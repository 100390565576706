import { Theme } from "@mui/material";

export default function Chip(baseTheme: Theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.common.white,
                    "& .MuiChip-label": {
                        fontWeight: 600,
                    },
                },
            },
        },
    };
}
