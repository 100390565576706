import React from "react";
import { Typography } from "@mui/material";
import { formatDateForSubmissions } from "helpers";
import { ISubmissionRow } from "types/Models";

export function DateCell({ data }: ISubmissionRow) {
    return (
        <Typography variant="button" color="action.focus">
            {formatDateForSubmissions(data.createdAt)}
        </Typography>
    );
}
