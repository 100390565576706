import React, { useLayoutEffect, useState } from "react";
import { api } from "api/api";
import { IStatuses, ISubmissionData } from "types/Models";
import Page from "components/Page";
import { AlertColor, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet, useOutletContext } from "react-router-dom";
import { LinkMoveBack } from "components/LinkMoveBack";
import { SmallNotification } from "components/SmallNotification";
import { Loader } from "components/Loader";
import { SnackBar } from "components/SnackBar";
import { routePaths } from "routes/routePaths";
import { getNotifications } from "pages/Dashboard/Dashboard.service";
import { Header } from "./components/Header";
import { Heading } from "./components/Heading";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
    },
    inner: {
        width: "100%",
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
        },
    },
    outlet: {
        flexDirection: "row",
        gap: "40px",
        alignItems: "flex-start",
        padding: "25px 20px 0 100px",
        width: "100%",
        marginBottom: "50px",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
        },
    },
}));

export function SubmissionReviewPage() {
    const classes = useStyles();
    const [notificationsCount, setNotificationsCount] = useState(0);
    const instId = localStorage.getItem("instId") || 0;
    const [isModalOpen, setModalOpen] = useState(!instId);
    const modalMessage = "Your submission wasn't found";
    const modalHeader = "Please try once again";
    const [statuses, setStatuses] = useState<IStatuses | undefined>(undefined);
    const [submissionData, setSubmissionData] = useState<ISubmissionData | undefined>(undefined);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState<AlertColor | undefined>("error");
    const [needUpdates, setNeedUpdates] = useState(true);

    useLayoutEffect(() => {
        api.get(`/onboarding/profile/insight?profileId=${instId}`)
            .then((result) => {
                setStatuses(result.data.data);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
            });
    }, [needUpdates]);

    useLayoutEffect(() => {
        getNotifications()
            .then((result) => {
                setNotificationsCount(result.data.totalCount);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
            });
    }, [needUpdates]);

    useLayoutEffect(() => {
        setLoaderOpen(true);
        api.get(`/onboarding/profile/overview?profileId=${instId}`)
            .then((result) => {
                setLoaderOpen(false);
                setSubmissionData(result.data.data);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            });
    }, [needUpdates]);
    return (
        <Page title="Submission Review | Staff Portal">
            <Stack className={classes.root}>
                <Stack width="100%" minHeight="100vh" direction="row">
                    <Stack className={classes.inner}>
                        <Header notificationsCount={notificationsCount} />
                        <LinkMoveBack
                            url={routePaths.submissions}
                            text="All Submissions"
                            padding="40px 90px"
                            back="white"
                        />
                        <Heading statuses={statuses} />
                        <Stack className={classes.outlet}>
                            <Outlet
                                context={{
                                    submissionData,
                                    instId,
                                    setSnackMessage,
                                    setSnackOpen,
                                    setSnackType,
                                    needUpdates,
                                    setNeedUpdates,
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {isModalOpen && (
                <SmallNotification
                    modalHeader={modalHeader}
                    modalMessage={modalMessage}
                    setModalOpen={setModalOpen}
                    type="error"
                />
            )}
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType={snackType}
            />
        </Page>
    );
}

export function useData() {
    return useOutletContext<any>();
}
