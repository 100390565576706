/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Stack, Box, Typography, SvgIcon } from "@mui/material";
import { ReactComponent as Approve } from "assets/Images/approve.svg";
import { ReactComponent as Rejected } from "assets/Images/rejected.svg";
import { ReactComponent as Wait } from "assets/Images/wait2.svg";

type SectionFooterPropsType = {
    status: string | undefined;
    message: string | undefined;
};

export function AccordionReviewFooter({ status, message }: SectionFooterPropsType) {
    const isApproved = status === "APPROVED";
    const isRejected = status === "UPDATE_REQUESTED";
    let reviewStatus;
    switch (status) {
        case "APPROVED":
            reviewStatus = "Approved";
            break;
        case "UPDATE_REQUESTED":
            reviewStatus = "Update requested";
            break;
        default:
            reviewStatus = "Pending review";
    }
    const logo = isApproved ? Approve : status === "UPDATE_REQUESTED" ? Rejected : Wait;
    const [isMessageShown, setIsMessageShown] = useState(false);
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <SvgIcon
                        component={logo}
                        inheritViewBox
                        sx={{
                            m: "5px",
                            fontSize: "22px",
                            color: ({ palette }) => palette.grey[300],
                        }}
                    />
                    <Typography variant="button">{reviewStatus}</Typography>
                </Stack>
                {isRejected && (
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                width: "max-content",
                                fontSize: "12px",
                                color: ({ palette }) => palette.action.active,
                                textTransform: "uppercase",
                                borderBottom: "1px dashed",
                                cursor: "pointer",
                            }}
                            onClick={() => setIsMessageShown(!isMessageShown)}
                        >
                            {isMessageShown ? "Hide Reasons " : "See Reasons below"}
                        </Typography>
                        {isMessageShown && (
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    pt: "10px",
                                    color: ({ palette }) => palette.action.active,
                                }}
                            >
                                &#x2022; {message}
                            </Typography>
                        )}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
}
