import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    AlertColor,
    Box,
    Pagination,
    Stack,
    SvgIcon,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { ISubmission } from "api/apiExample";
import { ReviewCell } from "pages/Dashboard/SubmissionsSection/components/ReviewCell";
import { formatDateForSubmissions } from "helpers";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { ISubmissionRow } from "types/Models";
import { SubmissionLabel } from "pages/Dashboard/SubmissionsSection/components/SubmissionLabel";
import { ReactComponent as Download } from "assets/Images/download.svg";
import { ModalCell } from "./SubmissionMenu";
import { Filter } from "./Filter";

interface ISubmProps {
    submissions: ISubmission[];
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    inner: {
        padding: "25px",
        width: "100%",
        height: "calc(100vh - 110px)",
        gap: "25px",
        marginBottom: "10px",
    },
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

function DateCell({ data }: ISubmissionRow) {
    return (
        <Typography variant="button" color="action.focus">
            {formatDateForSubmissions(data.createdAt)}
        </Typography>
    );
}

export const validationSchema = yup.object({
    reviewStatus: yup.string(),
    submissionStatus: yup.string(),
});

export function Submissions({
    submissions,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setTableUpdate,
    needTableUpdate,
}: ISubmProps) {
    const reversedSubmissions = [...submissions].reverse();
    const gridRef = useRef<AgGridReact<ISubmission>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);
    const classes = useStyles();
    const [filteredSubmissions, setFilteredSubmissions] = useState<ISubmission[] | undefined>(
        undefined,
    );
    const [gridApi, setGridApi] = useState<GridApi<ISubmission> | undefined>();
    const PAGE_SIZE = 1;
    const [pagesNumber, setPagesNumber] = useState(1);
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        reviewStatus: "",
        submissionStatus: "",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const goToFirstPage = useCallback(() => {
        if (gridRef.current) gridRef.current.api.paginationGoToFirstPage();
    }, []);
    const columnDefs = useMemo(
        () => [
            {
                headerName: "",
                field: "",
                checkboxSelection: true,
                flex: 1,
                minWidth: 50,
                suppressMovable: true,
                headerCheckboxSelection: true,
            },
            {
                headerName: "Bank ID",
                field: "id",
                flex: 1,
                minWidth: 100,
                suppressMovable: true,
                cellRenderer: ({ data }: ISubmissionRow) => `#${data.id}`,
            },
            {
                headerName: "Bank Name",
                field: "name",
                flex: 1,
                minWidth: 250,
                suppressMovable: true,
            },
            {
                headerName: "Date Created",
                field: "createdAt",
                cellRenderer: DateCell,
                flex: 1,
                minWidth: 200,
                suppressMovable: true,
            },
            {
                headerName: "Submission Status",
                field: "submissionStatus",
                cellRenderer: SubmissionLabel,
                flex: 2,
                minWidth: 200,
                suppressMovable: true,
            },
            {
                headerName: "Review Status",
                field: "reviewStatus",
                cellRenderer: ReviewCell,
                flex: 2,
                minWidth: 200,
                suppressMovable: true,
            },
            {
                headerName: "Filter",
                field: "",
                headerComponent: Filter,
                headerComponentParams: {
                    setFilteredSubmissions,
                    setFilterMenuOpen,
                    setFormValues,
                    formValues,
                    isFilterMenuOpen,
                    submissions: reversedSubmissions,
                    goToFirstPage,
                },
                cellRenderer: ModalCell,
                cellRendererParams: {
                    setLoaderOpen,
                    setSnackType,
                    setSnackMessage,
                    setSnackOpen,
                    needTableUpdate,
                    setTableUpdate,
                },
                flex: 1,
                minWidth: 150,
                suppressMovable: true,
            },
        ],
        [submissions, isFilterMenuOpen],
    );

    const onBtnExport = useCallback(() => {
        if (gridRef.current) {
            if (gridRef.current.api.getSelectedNodes().length) {
                gridRef.current.api.exportDataAsCsv({
                    columnKeys: ["id", "name", "createdAt", "submissionStatus", "reviewStatus"],
                    onlySelected: true,
                });
            }
        }
    }, []);
    const onPaginationChanged = () => {
        if (gridApi) {
            setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
            setPagesNumber(gridApi.paginationGetTotalPages());
        }
    };
    return (
        <Stack>
            <Stack className={classes.inner}>
                <Stack direction="row" justifyContent="space-between" color="white">
                    <Typography variant="h3" color="action.focus" marginBottom="12px">
                        Submissions
                    </Typography>
                    <Tooltip
                        title="Please select the submissions you would like to download"
                        placement="left"
                        open={isTooltipOpen}
                    >
                        <SvgIcon
                            component={Download}
                            viewBox="0 0 24 14"
                            sx={{ cursor: "pointer" }}
                            onClick={onBtnExport}
                            onMouseEnter={() => {
                                if (gridRef.current) {
                                    if (!gridRef.current.api.getSelectedNodes().length)
                                        setTooltipOpen(true);
                                }
                            }}
                            onMouseLeave={() => setTooltipOpen(false)}
                        />
                    </Tooltip>
                </Stack>
                <Stack
                    height="100%"
                    sx={{
                        border: "1px solid",
                        borderColor: ({ palette }) => palette.secondary.contrastText,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                    }}
                >
                    <Box className="ag-theme-alpine" style={{ height: "100%" }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowData={
                                filteredSubmissions &&
                                (formValues.reviewStatus || formValues.submissionStatus)
                                    ? filteredSubmissions
                                    : reversedSubmissions
                            }
                            rowSelection="multiple"
                            suppressRowClickSelection
                            onGridReady={(params) => setGridApi(params.api)}
                            pagination
                            paginationPageSize={PAGE_SIZE}
                            onPaginationChanged={onPaginationChanged}
                            paginationAutoPageSize
                        />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            background: ({ palette }) => palette.common.white,
                            padding: "30px",
                            borderTop: "2px solid",
                            borderColor: ({ palette }) => palette.secondary.contrastText,
                        }}
                    >
                        <Pagination
                            count={pagesNumber}
                            ref={paginationRef}
                            onChange={(e, page) => {
                                setCurrentPage(page);
                                if (gridRef.current)
                                    gridRef.current.api.paginationGoToPage(page - 1);
                            }}
                            shape="rounded"
                            page={currentPage}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
