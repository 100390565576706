import React, { useCallback, useRef, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import { ISubmission } from "api/apiExample";
import { ReactComponent as MenuIcon } from "assets/Images/menu.svg";
import { useNavigate } from "react-router-dom";
import { routePaths } from "routes/routePaths";
import { api } from "api/api";
import { StyledButton } from "components/StyledButton";

interface IActionMenuProps {
    submission: ISubmission;
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: string) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

interface IMenuProps {
    data: ISubmission;
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: string) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

export function SubmissionMenu({
    submission,
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    setTableUpdate,
    needTableUpdate,
}: IActionMenuProps) {
    const navigate = useNavigate();
    const ref = useRef(null);
    const [chosenSubmission, setChosenSubmission] = useState<ISubmission | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => setDialogOpen(false);

    const doViewSubmission = useCallback(() => {
        setIsOpen(false);
        navigate(routePaths.review);
    }, [chosenSubmission]);

    const doDeleteSubmission = useCallback(() => {
        setDialogOpen(true);
        setIsOpen(false);
    }, [chosenSubmission]);

    const agreeToDelete = useCallback(() => {
        setLoaderOpen(true);
        api.post("/onboarding/business/delete", { institutionID: localStorage.getItem("instId") })
            .then(() => {
                setSnackType("success");
                setSnackMessage("You successfully deleted submission");
                setSnackOpen(true);
                setLoaderOpen(false);
                setTableUpdate(!needTableUpdate);
            })
            .catch((err) => {
                setSnackType("error");
                setSnackMessage(err.message);
                setSnackOpen(true);
                setLoaderOpen(false);
            });
    }, [chosenSubmission]);

    const doChooseSubmission = useCallback(() => {
        setChosenSubmission(submission);
        localStorage.setItem("instId", submission.id.toString());
    }, [submission]);

    return (
        <>
            <IconButton
                ref={ref}
                onClick={() => {
                    doChooseSubmission();
                    setIsOpen(true);
                }}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={doViewSubmission}>View</MenuItem>
                <MenuItem onClick={doDeleteSubmission}>Delete</MenuItem>
            </Menu>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle sx={{ fontSize: "18px" }}>
                    Are you sure you want to delete this submission?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>This action cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={agreeToDelete}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.grey[300],
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                        isAccept
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

export function ModalCell({
    data,
    setLoaderOpen,
    setSnackType,
    setSnackMessage,
    setSnackOpen,
    setTableUpdate,
    needTableUpdate,
}: IMenuProps) {
    return (
        <SubmissionMenu
            submission={data}
            setLoaderOpen={setLoaderOpen}
            setSnackType={setSnackType}
            setSnackMessage={setSnackMessage}
            setSnackOpen={setSnackOpen}
            needTableUpdate={needTableUpdate}
            setTableUpdate={setTableUpdate}
        />
    );
}
