import React, { ReactNode } from "react";
import { Stack, Icon, Typography } from "@mui/material";

export interface IRewLabelProps {
    icon: ReactNode;
    text: string;
}

export function ReviewLabel({ icon, text }: IRewLabelProps) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Icon sx={{ height: "100%", fontSize: "14px" }}>{icon}</Icon>
            <Typography variant="button">{text}</Typography>
        </Stack>
    );
}
