import React, { useLayoutEffect, useState } from "react";
import { Box, Typography, Theme, Stack, SvgIcon, useTheme } from "@mui/material";
import { routePaths } from "routes/routePaths";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { IStepperData } from "types/Models";
import { useData } from "../SubmissionReviewPage";

type StepperPropTypes = {
    step: number;
};

const useStyles = makeStyles((theme: Theme) => ({
    stepper: {
        width: "250px",
        height: "auto",
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
        borderRadius: "7px",
        padding: "25px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        gap: "40px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            maxWidth: "900px",
            flexDirection: "row",
            gap: "10px",
            padding: "25px 10px ",
            justifyContent: "space-around",
        },
    },
    inner: {
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "20px",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
        },
    },
    header: {
        [theme.breakpoints.down("lg")]: {
            marginLeft: "0 !important",
            alignItems: "center",
            textAlign: "center",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

export function SimpleVerticalStepper({ step }: StepperPropTypes) {
    const classes = useStyles();
    const theme = useTheme();
    const data = useData()?.submissionData;
    const KYCStatus =
        data?.documentStats?.approvedPercentage === 100 ? "APPROVED" : "UPDATE_REQUESTED";
    const isKYCPendingReview =
        data?.documentStats?.approvedPercentage === 0 &&
        data?.documentStats?.updateRequestedPercentage === 0;
    const [stepperData, setStepperData] = useState<IStepperData[] | []>([]);
    useLayoutEffect(
        () =>
            setStepperData([
                {
                    id: 1,
                    header: "Business Information",
                    url: routePaths.review,
                    status: data?.profile?.reviewStatus,
                },
                {
                    id: 2,
                    header: "Personalisation",
                    url: routePaths.personalization_review,
                    status: data?.personalization?.reviewStatus,
                },
                {
                    id: 3,
                    header: "KYC Information",
                    url: routePaths.kyc_review,
                    status: isKYCPendingReview ? "PENDING_REVIEW" : KYCStatus,
                },
                {
                    id: 4,
                    header: "API Documentation",
                    url: routePaths.api_review,
                    status: data?.settlements?.reviewStatus,
                },
                {
                    id: 5,
                    header: "Developer Console",
                    url: routePaths.dev_console_review,
                    status: data?.iswPortal?.iswPortalReviewStatus,
                },
                {
                    id: 6,
                    header: "Approval",
                    url: routePaths.approval_review,
                    status: data?.overallReviewStatus,
                },
            ]),
        [data],
    );
    const getColor = (status: string) =>
        status === "UPDATE_REQUESTED" ? theme.palette.warning.light : theme.palette.primary.light;
    return (
        <Stack className={classes.stepper}>
            {stepperData.map(({ id, header, url, status }: IStepperData) => (
                <Link
                    key={id}
                    to={url}
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <Stack className={classes.inner}>
                        <Box
                            sx={{
                                width: "26px",
                                height: "26px",
                                borderRadius: "50%",
                                border: "1px solid",
                                flexShrink: 0,
                                borderColor:
                                    (status === "PENDING_REVIEW" ||
                                        status === "REVIEW_IN_PROGRESS" ||
                                        status === null ||
                                        status === undefined) &&
                                    step !== id
                                        ? ({ palette }) => palette.grey[300]
                                        : getColor(status),
                                backgroundColor:
                                    status === "PENDING_REVIEW" ||
                                    status === "REVIEW_IN_PROGRESS" ||
                                    status === null ||
                                    status === undefined
                                        ? "transparent"
                                        : getColor(status),
                            }}
                        >
                            {status !== "PENDING_REVIEW" && (
                                <SvgIcon
                                    component={
                                        status === "UPDATE_REQUESTED"
                                            ? PriorityHighRoundedIcon
                                            : DoneIcon
                                    }
                                    inheritViewBox
                                    sx={{
                                        mt: "2px",
                                        ml: "2px",
                                        fontSize: "20px",
                                        color: ({ palette }) => palette.common.white,
                                    }}
                                />
                            )}
                        </Box>
                        <Stack className={classes.header}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    color:
                                        status === "APPROVED" || step === id
                                            ? theme.palette.primary.light
                                            : ({ palette }) => palette.grey[300],
                                }}
                            >
                                {header}
                            </Typography>
                        </Stack>
                    </Stack>
                </Link>
            ))}
        </Stack>
    );
}
