import { Theme } from "@mui/material";

export default function Button(baseTheme: Theme) {
    return {
        MuiButton: {
            styleOverrides: {
                contained: {
                    color: baseTheme.palette.primary.dark,
                    backgroundColor: baseTheme.palette.primary.light,
                    textAlign: "center",
                    border: "none",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                    },
                },
                outlined: {
                    boxSizing: "content-box",
                    textAlign: "center",
                    border: "1px solid",
                    borderColor: baseTheme.palette.primary.main,
                    boxShadow: "none",
                    "&:hover": {
                        background: baseTheme.palette.primary.lighter,
                    },
                },
                root: {
                    width: "197px",
                    height: "44px",
                    padding: 0,
                    color: baseTheme.palette.primary.light,
                    borderRadius: "6px",
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Inter-Medium",
                    "& .MuiIconButton-root": {
                        "&:hover": {
                            backgroundColor: "none",
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.MuiMenuItem-root": {
                        "&:hover": {
                            color: baseTheme.palette.primary.light,
                            backgroundColor: "transparent",
                        },
                        color: baseTheme.palette.text.secondary,
                    },
                    "&.MuiPaginationItem-root": {
                        fontSize: "14px",
                        color: baseTheme.palette.action.focus,
                        "&.Mui-selected": {
                            backgroundColor: baseTheme.palette.success.light,
                        },
                        "&:hover": {
                            backgroundColor: baseTheme.palette.action.disabledBackground,
                        },
                    },
                },
            },
        },
    };
}
