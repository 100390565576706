import React from "react";
import { Box, List } from "@mui/material";
import { ReactComponent as Dashboard } from "assets/Images/Dashboard.svg";
import { ReactComponent as Submissions } from "assets/Images/Submissions.svg";
import { ReactComponent as Settings } from "assets/Images/Settings.svg";
import { ReactComponent as Management } from "assets/Images/Management.svg";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import { routePaths } from "routes/routePaths";
import { useLocation } from "react-router-dom";
import { NavItem } from "./NavItem";
import { LogoutButton } from "./LogoutButton";

const navConfig = [
    {
        id: 1,
        title: "Dashboard",
        path: routePaths.dashboard,
        icon: <Dashboard />,
    },
    {
        id: 2,
        title: "Submissions",
        path: routePaths.submissions,
        icon: <Submissions />,
    },
    {
        id: 3,
        title: "Notifications",
        path: routePaths.notifications,
        icon: <MarkEmailUnreadOutlinedIcon />,
    },
    {
        id: 4,
        title: "User Management",
        path: routePaths.user_management,
        icon: <Management />,
    },
    {
        id: 5,
        title: "System Settings",
        path: "sett",
        icon: <Settings />,
    },
];

export function NavBar() {
    const { pathname } = useLocation();
    const checkRouteMatch = (itemPath: string) => {
        const secondSlashindex = pathname.indexOf("/", 1);
        if (secondSlashindex !== -1) {
            const slicedPath = pathname.slice(0, secondSlashindex);
            return itemPath === slicedPath;
        }
        return itemPath === pathname;
    };
    return (
        <Box sx={{ width: "100%", maxWidth: 280, bgcolor: "primary.dark" }}>
            <List>
                {navConfig.map((item) => (
                    <NavItem key={item.id} item={item} active={checkRouteMatch} />
                ))}
                <LogoutButton />
            </List>
        </Box>
    );
}
