import { Theme } from "@mui/material";

export default function Table(baseTheme: Theme) {
    return {
        MuiTableBody: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.action.focus,

                    "& .MuiTableCell-root": {
                        color: baseTheme.palette.action.focus,
                        fontWeight: 600,
                        fontSize: "14px",
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.palette.primary.lighter,
                },
            },
        },
    };
}
