import { alpha } from "@mui/material/styles";

// SETUP COLORS
const GREY = {
    0: "#FFFFFF",
    50: "#F8F9FC",
    100: "#E8E8E8",
    200: "#9CA3AF",
    300: "#6B7280",
    400: "#7F91A8",
    500: "#6D7E95",
    600: "#6E84AC",
    700: "#4B4B4B",
    800: "#353F50",
    900: "#021921",
    400_58: alpha("#7F91A8", 0.58),
    400_16: alpha("#7F91A8", 0.16),
};

const PRIMARY = {
    lighter: "#F4FFFE",
    light: "#00DDC7",
    main: "#02B8A6",
    dark: "#021921",
    contrastText: "#fff",
};
const SECONDARY = {
    lighter: "#FCD34D",
    light: "#F3F4F6",
    main: "#F7931E",
    dark: "#B8592B",
    contrastText: "#ebebeb",
};
const INFO = {
    main: "#0275D8",
    dark: "#354052",
    contrastText: GREY[300],
};
const SUCCESS = {
    lighter: "#ECFFFD",
    light: "#DCFCE7",
    main: "#22C55E",
    dark: "#36743D",
    darker: "#4ADE80",
    contrastText: GREY[800],
};
const WARNING = {
    light: "#FBBF24",
    main: "#FCD34D",
    dark: "#B8592B",
    contrastText: "#FFF1E1",
};
const ERROR = {
    lighter: "#FFF1F1",
    light: "#EF4444",
    main: "#DC2626",
    dark: "#ED342B",
    darker: "#DC4437",
    contrastText: "#fff",
};

const palette = {
    common: { black: "#000", white: "#fff" },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    text: { primary: GREY[400], secondary: GREY[200], disabled: GREY[500] },
    background: { paper: "#fff", default: GREY[50] },
    action: {
        active: GREY[300],
        hover: GREY[400_58],
        selected: GREY[400_58],
        disabled: GREY[400_58],
        disabledBackground: GREY[400_16],
        focus: GREY[700],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};

export default palette;
