import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "assets/Images/logo.png";
import { routePaths } from "routes/routePaths";

type LogoPropsType = {
    width: string;
    height: string;
};

export function Logo({ width, height }: LogoPropsType) {
    const navigate = useNavigate();

    return (
        <Box
            data-testid="logo"
            onClick={() => navigate(routePaths.dashboard)}
            sx={{
                "&:hover": {
                    cursor: "pointer",
                },
            }}
        >
            <Box
                sx={{
                    width,
                    height,
                    backgroundImage: `url(${logo})`,
                    backgroundSize: "cover",
                }}
            />
        </Box>
    );
}
