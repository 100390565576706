import React, { useState } from "react";
import { Stack, Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "ag-grid-community/styles/ag-grid.css";
import { INotification } from "types/Models";
import { Modal } from "components/Modal";
import { Notification } from "./Notification";

interface INotifProps {
    notifications: INotification[];
}

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        height: "100%",
        backgroundColor: theme.palette.common.white,
        border: "1px solid",
        borderColor: "#ebebeb",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "14px 20px",
        borderBottom: "2px solid #ebebeb",
    },
}));

export function NotificationsSection({ notifications }: INotifProps) {
    const classes = useStyles();
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleClose = () => {
        setModalOpen(false);
    };
    return (
        <Stack className={classes.section}>
            <Stack className={classes.header}>
                <Typography variant="button" color="common.black">
                    Recent Notifications
                </Typography>
                <Typography
                    variant="button"
                    color="primary.main"
                    component={Link}
                    to="/notifications"
                >
                    View All
                </Typography>
            </Stack>
            {notifications.length ? (
                <Stack spacing={0.5}>
                    {notifications.slice(0, 4).map((item) => (
                        <Notification
                            key={item.id}
                            item={item}
                            setNotification={setNotification}
                            setModalOpen={setModalOpen}
                        />
                    ))}
                </Stack>
            ) : (
                <Stack
                    sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography fontSize="13px" color="common.black">
                        No Notifications To Show
                    </Typography>
                </Stack>
            )}

            <Modal isModalOpen={isModalOpen} handleClose={handleClose} element={notification} />
        </Stack>
    );
}
