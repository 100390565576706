import { TypographyOptions } from "@mui/material/styles/createTypography";

function pxToRem(value: number) {
    return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
    return {
        "@media (min-width:600px)": {
            fontSize: pxToRem(sm),
        },
        "@media (min-width:900px)": {
            fontSize: pxToRem(md),
        },
        "@media (min-width:1200px)": {
            fontSize: pxToRem(lg),
        },
    };
}

const FONT_PRIMARY = "Averta, sans-serif";
const FONT_SECONDARY = "Inter-Medium, sans-serif";

const typography: TypographyOptions = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontFamily: FONT_SECONDARY,
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(28),
        ...responsiveFontSizes({ sm: 24, md: 26, lg: 28 }),
    },
    h2: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(25),
    },
    h3: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
    },
    h4: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    h5: {
        fontWeight: 800,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
    },
    h6: {
        fontWeight: 800,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    subtitle1: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    subtitle2: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(20),
    },
    body1: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    body2: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    caption: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(10),
    },
    overline: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
        letterSpacing: 1.1,
        textTransform: "uppercase",
    },
    button: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
        textTransform: "none",
    },
};

export default typography;
