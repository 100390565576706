import { useCallback, useMemo, useState } from "react";
import { api } from "api/api";

export type LoginBodyType = {
    email: string;
    password: string;
    isRemembered: boolean;
};

const useAuthService = () => {
    const authDataBuild = useMemo(() => {
        try {
            return JSON.parse(localStorage.getItem("currentUser") || "");
        } catch (e) {
            return null;
        }
    }, []);
    const [authData, setAuthData] = useState(authDataBuild);
    const [isAuthenticated, setIsAuthenticated] = useState(!!authData);

    const login = useCallback(
        (body: LoginBodyType) =>
            api.post("/auth/login", body).then((res) => {
                const { data } = res;
                const currentUser = { ...data.data };
                if (currentUser?.token) {
                    localStorage.setItem("currentUser", JSON.stringify(currentUser));
                    localStorage.setItem("token", currentUser.token);
                    setAuthData(currentUser);
                    setIsAuthenticated(!!authData);
                }
                return res;
            }),
        [],
    );

    const logout = useCallback(
        () =>
            new Promise<void>((resolve) => {
                localStorage.removeItem("token");
                localStorage.removeItem("currentUser");
                localStorage.removeItem("instId");
                setIsAuthenticated(false);
                resolve();
            }),
        [],
    );

    return {
        authData,
        isAuthenticated,
        login,
        logout,
    };
};

export default useAuthService;
