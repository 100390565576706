import React, { forwardRef } from "react";
import { Box } from "@mui/material";

interface IPageProps {
    title: string;
    children: React.ReactNode;
}

const Page = forwardRef(({ children, title = "", ...other }: IPageProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box ref={ref} {...other}>
        <title>{title}</title>
        {children}
    </Box>
));

Page.displayName = "Page";

export default Page;
