/* eslint-disable no-nested-ternary */
import React from "react";
import { Box, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { ReactComponent as Approve } from "assets/Images/approve2.svg";
import { ReactComponent as Rejected } from "assets/Images/rejected.svg";
import { ReactComponent as Wait } from "assets/Images/wait2.svg";

type SectionHeaderPropsType = {
    name: string;
    needStatus: boolean;
    // eslint-disable-next-line react/require-default-props
    status?: string | undefined;
};

export function SectionHeader({ name, needStatus, status }: SectionHeaderPropsType) {
    const theme = useTheme();
    const isApproved = status === "APPROVED";
    let reviewStatus;
    switch (status) {
        case "APPROVED":
            reviewStatus = "Approved";
            break;
        case "UPDATE_REQUESTED":
            reviewStatus = "Update requested";
            break;
        default:
            reviewStatus = "Pending review";
    }
    const logo = isApproved ? Approve : status === "UPDATE_REQUESTED" ? Rejected : Wait;
    let textColor;
    switch (status) {
        case "APPROVED":
            textColor = theme.palette.primary.light;
            break;
        case "UPDATE_REQUESTED":
            textColor = theme.palette.error.light;
            break;
        default:
            textColor = theme.palette.warning.main;
    }
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "18px",
                        color: ({ palette }) => palette.grey[200],
                    }}
                >
                    {name}
                </Typography>
                {needStatus && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <SvgIcon
                            component={logo}
                            inheritViewBox
                            sx={{
                                m: "5px",
                                fontSize: "22px",
                                color: ({ palette }) => palette.grey[300],
                            }}
                        />
                        <Typography variant="caption" color={textColor}>
                            {reviewStatus}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Box
                sx={{
                    width: "100%",
                    height: "1px",
                    margin: "auto",
                    m: "37px 0",
                    bgcolor: "#C4C4C4",
                }}
            />
        </>
    );
}
