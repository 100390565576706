/* eslint-disable no-param-reassign */
interface IMonthNames {
    "01": string;
    "02": string;
    "03": string;
    "04": string;
    "05": string;
    "06": string;
    "07": string;
    "08": string;
    "09": string;
    "10": string;
    "11": string;
    "12": string;
}

const monthNames = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
};

export const formatDate = (date: string | number) => {
    try {
        if (typeof date === "number") {
            date = new Date(date).toISOString();
        }
        const dateISO = new Date(date);
        const localDate = dateISO.toLocaleDateString("en-GB");
        const localTime = dateISO.toLocaleTimeString("en-GB");
        const [day, month, year] = localDate.split("/");
        const monthName = monthNames[month as keyof IMonthNames];
        // eslint-disable-next-line prefer-const
        let [hour, minutes] = localTime.split(":");
        const interval = +hour > 12 ? "PM" : "AM";
        if (+hour > 12) {
            hour = (+hour - 12).toString();
        }
        return `${day}-${monthName}-${year} | ${hour}:${minutes}${interval}`;
    } catch {
        return "";
    }
};

export const formatDateForSubmissions = (date: string | number) => {
    try {
        if (typeof date === "number") {
            date = new Date(date).toISOString();
        }
        const dateISO = new Date(date);
        const localDate = dateISO.toLocaleDateString("en-GB");
        const [day, month, year] = localDate.split("/");
        const monthName = monthNames[month as keyof IMonthNames];
        return `${day}-${monthName}-${year}`;
    } catch {
        return "";
    }
};

export const trimName = (name: string) => {
    if (name) {
        const arr = name.split(" ");
        if (arr.length > 1) {
            return `${arr[0][0]}${arr[1][0]}`;
        }
        return `${arr[0][0]}${arr[0][1]}`;
    }
    return name;
};

export const rolesList = [
    { id: 1, code: "REVIEWER", descr: "Reviewer" },
    { id: 2, code: "ADMIN", descr: "Admin" },
];

export const decapitalize = (str: string) => {
    const newStr = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return newStr;
};
