import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes/routes";

export function App() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AppRoutes />
        </BrowserRouter>
    );
}

export default App;
