/* eslint-disable react/destructuring-assignment */
import React, { useRef } from "react";
import {
    Box,
    Button,
    Dialog,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    Theme,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ReactComponent as FilterIcon } from "assets/Images/filter.svg";
import { AgGridReact } from "ag-grid-react";
import { StyledButton } from "components/StyledButton";
import { ISearchUsers, IUser } from "types/Models";
import { makeStyles } from "@mui/styles";

interface IFilterProps {
    setFilteredUsers: (value: IUser[] | undefined) => void;
    setFilterMenuOpen: (value: boolean) => void;
    setFormValues: ({ status }: { status: string | boolean }) => void;
    goToFirstPage: () => void;
    formValues: { status: string };
    isFilterMenuOpen: boolean;
    users: IUser[];
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "60vw",
        maxWidth: "600px",
        padding: "50px",
        gap: "50px",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            gap: "30px",
        },
    },
    buttons: {
        flexDirection: "row",
        justifyContent: "center",
        gap: "30px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "15px",
        },
    },
    button: {
        width: "40%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const statuses = [
    { id: 1, name: "Active", code: "false" },
    { id: 2, name: "Inactive", code: "true" },
];

export const validationSchema = yup.object({
    status: yup.boolean(),
});

export function Filter({
    setFilteredUsers,
    setFilterMenuOpen,
    setFormValues,
    formValues,
    isFilterMenuOpen,
    users,
    goToFirstPage,
}: IFilterProps) {
    const classes = useStyles();
    const gridRef = useRef<AgGridReact<IUser>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);

    return (
        <Stack sx={{ height: "auto" }}>
            <Formik<ISearchUsers>
                initialValues={formValues}
                onSubmit={() => console.log("here")}
                validateOnBlur
                validationSchema={validationSchema}
            >
                {({ values, handleChange }) => (
                    <Form style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    sx={{ cursor: "pointer", alignItems: "center" }}
                                    onClick={() => {
                                        setFilterMenuOpen(true);
                                    }}
                                >
                                    <SvgIcon component={FilterIcon} viewBox="0 0 24 14" />
                                    Filter
                                </Stack>
                            </Grid>
                            <Dialog open={isFilterMenuOpen}>
                                <Stack className={classes.container}>
                                    <InputLabel sx={{ fontSize: "20px", color: "primary.main" }}>
                                        Select status
                                    </InputLabel>
                                    <Select
                                        name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                        // pay attention here! delay in values.reviewStatus
                                        sx={{
                                            background: ({ palette }) => palette.common.white,
                                            "& .MuiInputBase-input": {
                                                color: ({ palette }) => palette.text.primary,
                                                fontSize: "14px",
                                                fontWeight: "600",
                                            },
                                            width: "100%",
                                        }}
                                        inputProps={{
                                            style: { color: "text.primary" },
                                        }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        {statuses.map((status) => (
                                            <MenuItem key={status.id} value={status.code}>
                                                {status.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Stack className={classes.buttons}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setFilterMenuOpen(false)}
                                            className={classes.button}
                                        >
                                            Cancel
                                        </Button>
                                        <Box className={classes.button}>
                                            <StyledButton
                                                width="100%"
                                                isAccept
                                                text="Filter"
                                                onHandleClick={() => {
                                                    setFilterMenuOpen(false);
                                                    setFormValues(values);
                                                    setFilteredUsers(
                                                        users.filter((elem: IUser): boolean => {
                                                            if (values.status === "All")
                                                                return true;
                                                            return (
                                                                elem.disabled.toString() ===
                                                                values.status
                                                            );
                                                        }),
                                                    );
                                                    if (gridRef.current && paginationRef.current) {
                                                        gridRef.current.api.paginationGoToFirstPage();
                                                    }
                                                    goToFirstPage();
                                                }}
                                                isDisabled={false}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Dialog>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Stack>
    );
}
