import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { AlertColor, Box, Pagination, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { StyledButton } from "components/StyledButton";
import { IUser, IUserRow } from "types/Models";
import { useNavigate } from "react-router-dom";
import { routePaths } from "routes/routePaths";
import { api } from "api/api";
import { decapitalize } from "helpers";
import { Filter } from "./components/Filter";
import { StatusLabel } from "./components/StatusLabel";
import { ActionMenu } from "./components/ActionMenu";

/* const usersMock = [
    {
        id: 1,
        firstName: "Layiwola",
        lastName: "Adedela",
        email: "layiwoleadedela@lsmfb.com",
        role: "USER",
        disabled: null,
    },
    {
        id: 2,
        firstName: "Mandela",
        lastName: "Armstrong",
        email: "mandelaarmstrong@mfb.com",
        role: "USER",
        disabled: false,
    },
    {
        id: 3,
        firstName: "Igbokwe",
        lastName: "Matthew",
        email: "igbokwemat@hushmfb.com",
        role: "USER",
        disabled: true,
    },
    {
        id: 4,
        firstName: "Salem",
        lastName: "Mathodiale",
        email: "salemmathod@mfb.salem",
        role: "ADMIN",
        disabled: false,
    },
    {
        id: 5,
        firstName: "Abdul-Ansarudeen",
        lastName: "Wenger",
        email: "abdulwenger@ansa.mfb",
        role: "ADMIN",
        disabled: true,
    },
    {
        id: 6,
        firstName: "Loveth",
        lastName: "Uhuru-Uruchi",
        email: "lovethuruchi@uhurumfb.com",
        role: "ADMIN",
        disabled: null,
    },
    {
        id: 7,
        firstName: "Abdulrasheed",
        lastName: "Robotto",
        email: "abdulrob@interswitch.com",
        role: "USER",
        disabled: true,
    },
    {
        id: 8,
        firstName: "Alex",
        lastName: "Ricardo",
        email: "alexwen@wenger.com",
        role: "ADMIN",
        disabled: false,
    },
]; */

interface IUserManagementProps {
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    inner: {
        padding: "25px",
        width: "100%",
        height: "calc(100vh - 110px)",
        gap: "25px",
        marginBottom: "10px",
    },
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

export const validationSchema = yup.object({
    reviewStatus: yup.string(),
    submissionStatus: yup.string(),
});

export function UserManagement({
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
}: IUserManagementProps) {
    const gridRef = useRef<AgGridReact<IUser>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);
    const classes = useStyles();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [needTableUpdate, setTableUpdate] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<IUser[] | undefined>(undefined);
    const [gridApi, setGridApi] = useState<GridApi<IUser> | undefined>();
    const PAGE_SIZE = 1;
    const [pagesNumber, setPagesNumber] = useState(1);
    const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        status: "All",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const goToFirstPage = useCallback(() => {
        if (gridRef.current) gridRef.current.api.paginationGoToFirstPage();
    }, []);

    useLayoutEffect(() => {
        setLoaderOpen(true);
        api.get("/users/admins/?pageSize=2000")
            // &role=ADMIN,REVIEWER
            .then((result: any) => {
                setUsers(result.data.data);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, [needTableUpdate]);
    const columnDefs = useMemo(
        () => [
            {
                headerName: "",
                field: "",
                flex: 0.2,
                minWidth: 10,
                suppressMovable: true,
            },
            {
                headerName: "First Name",
                field: "firstName",
                flex: 1,
                minWidth: 250,
                suppressMovable: true,
                cellRenderer: ({ data }: IUserRow) => data.firstName,
            },
            {
                headerName: "Last Name",
                field: "lastName",
                flex: 1,
                minWidth: 250,
                suppressMovable: true,
                cellRenderer: ({ data }: IUserRow) => data.lastName,
            },
            {
                headerName: "Email Address",
                field: "email",
                flex: 1,
                minWidth: 350,
                suppressMovable: true,
                cellRenderer: ({ data }: IUserRow) => data.email,
            },
            {
                headerName: "Role",
                field: "role",
                flex: 2,
                minWidth: 100,
                suppressMovable: true,
                cellRenderer: ({ data }: IUserRow) => decapitalize(data.role),
            },
            {
                headerName: "Status",
                field: "status",
                flex: 2,
                minWidth: 100,
                suppressMovable: true,
                cellRenderer: StatusLabel,
            },
            {
                headerName: "Filter",
                field: "",
                headerComponent: Filter,
                headerComponentParams: {
                    setFilteredUsers,
                    setFilterMenuOpen,
                    setFormValues,
                    formValues,
                    isFilterMenuOpen,
                    users,
                    goToFirstPage,
                },
                cellRenderer: ActionMenu,
                cellRendererParams: {
                    setLoaderOpen,
                    setSnackType,
                    setSnackMessage,
                    setSnackOpen,
                    needTableUpdate,
                    setTableUpdate,
                },
                flex: 1,
                minWidth: 100,
                suppressMovable: true,
            },
        ],
        [users, isFilterMenuOpen],
    );

    const onPaginationChanged = () => {
        if (gridApi) {
            setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
            setPagesNumber(gridApi.paginationGetTotalPages());
        }
    };
    return (
        <Stack>
            <Stack className={classes.inner}>
                <Stack direction="row" justifyContent="space-between" color="white">
                    <Typography variant="h3" color="action.focus" marginBottom="12px">
                        User Management
                    </Typography>
                    <StyledButton
                        width="197px"
                        text="Create new user"
                        isAccept
                        isDisabled={false}
                        onHandleClick={() => navigate(routePaths.create_user)}
                    />
                </Stack>
                <Stack
                    height="100%"
                    sx={{
                        border: "1px solid",
                        borderColor: ({ palette }) => palette.secondary.contrastText,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                    }}
                >
                    <Box className="ag-theme-alpine" style={{ height: "100%" }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowData={
                                filteredUsers && formValues.status !== "All" ? filteredUsers : users
                            }
                            rowSelection="multiple"
                            suppressRowClickSelection
                            onGridReady={(params) => setGridApi(params.api)}
                            pagination
                            paginationPageSize={PAGE_SIZE}
                            onPaginationChanged={onPaginationChanged}
                            paginationAutoPageSize
                        />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            background: ({ palette }) => palette.common.white,
                            padding: "30px",
                            borderTop: "2px solid",
                            borderColor: ({ palette }) => palette.secondary.contrastText,
                        }}
                    >
                        <Pagination
                            count={pagesNumber}
                            ref={paginationRef}
                            onChange={(e, page) => {
                                setCurrentPage(page);
                                if (gridRef.current)
                                    gridRef.current.api.paginationGoToPage(page - 1);
                            }}
                            shape="rounded"
                            page={currentPage}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
