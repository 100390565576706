import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

type ILinkPropsType = {
    url: string;
    text: string;
    padding: string;
    back: string;
};

export function LinkMoveBack({ url, text, padding, back }: ILinkPropsType) {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                width: "100%",
                height: "100px",
                padding,
                backgroundColor: back,
            }}
        >
            <Link
                to={url}
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "24px",
                    textDecoration: "none",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: theme.palette.grey[600],
                }}
                data-testid="back"
            >
                <ArrowBackIosNew
                    sx={{
                        width: "24px",
                        height: "24px",
                        color: ({ palette }) => palette.grey[600],
                        mr: "8px",
                    }}
                />
                <Typography variant="button">{text}</Typography>
            </Link>
        </Stack>
    );
}
