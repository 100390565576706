import { Theme } from "@mui/material";

export default function List(baseTheme: Theme) {
    return {
        MuiList: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    "& .MuiListItemIcon-root": {
                        textAlign: "center",
                        color: baseTheme.palette.primary.light,
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    paddingLeft: "30px",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    display: "flex",
                    height: "45px",
                    alignItems: "center",
                },
            },
        },
    };
}
