import React from "react";
import { Box, IconButton, Theme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";

interface IDashboardNavbarProps {
    onOpenSidebar: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    burger: {
        marginRight: "20px",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    iconButton: {
        marginRight: 1,
    },
}));

export function BurgerMenu({ onOpenSidebar }: IDashboardNavbarProps) {
    const classes = useStyles();
    return (
        <Box className={classes.burger}>
            <IconButton onClick={onOpenSidebar}>
                <MenuIcon />
            </IconButton>
        </Box>
    );
}
