import React from "react";
import { Avatar, Badge, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useAuthService from "hooks/useAuthService";
import { Logo } from "components/Logo";
import { trimName } from "helpers";

interface IHeaderProps {
    notificationsCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        flexDirection: "row",
        width: "100%",
        height: "125px",
        justifyContent: "space-between",
        padding: "0 60px",
        borderBottom: `solid ${theme.palette.grey[100]} 1px`,
        backgroundColor: theme.palette.common.white,
        boxSizing: "border-box",
    },
    heading: {
        fontWeight: "400",
        fontSize: "14px",
        color: theme.palette.text.secondary,
        letterSpacing: "0.21em",
    },
    userInfo: {
        flexDirection: "row",
        alignItems: "center",
        gap: "45px",
        [theme.breakpoints.down("sm")]: {
            gap: 0,
        },
    },
    user: {
        alignItems: "flex-end",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    userName: {
        fontWeight: "700",
        fontSize: "16px",
        letterSpacing: "0.03em",
        color: theme.palette.common.black,
    },
    avatar: {
        width: "46px",
        height: "46px",
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: "20px",
    },
}));

export function Header({ notificationsCount }: IHeaderProps) {
    const classes = useStyles();
    const { authData } = useAuthService();
    const userName: string | undefined | null = authData.institution?.name;

    return (
        <Stack className={classes.header} data-testid="header">
            <Stack direction="row" alignItems="center" spacing={3}>
                <Logo width="150px" height="50px" />
                <Typography className={classes.heading}>STAFF PORTAL</Typography>
            </Stack>
            <Stack className={classes.userInfo}>
                <Stack className={classes.user}>
                    <Typography className={classes.userName}>
                        Hello, {userName || "User"}
                    </Typography>
                    <Typography
                        variant="button"
                        sx={{
                            color: "text.primary",
                            letterSpacing: "0.03em",
                        }}
                    >
                        Staff Admin
                    </Typography>
                </Stack>
                <Badge badgeContent={notificationsCount} color="error" sx={{ fontSize: "12px" }}>
                    <Avatar data-testid="avatar" className={classes.avatar}>
                        {userName ? trimName(userName) : trimName("User")}
                    </Avatar>
                </Badge>
            </Stack>
        </Stack>
    );
}
