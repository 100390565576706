import React from "react";
import { Button, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { api } from "api/api";
import { routePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "components/StyledButton";
import axios from "axios";
import { KYCType } from "types/Models";
import { SectionHeader } from "./SectionHeader";
import { SimpleVerticalStepper } from "./SimpleVerticalStepper";
import { useData } from "../SubmissionReviewPage";
import { KYCContent } from "./KYCContent";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "75%",
        maxWidth: "900px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    inner: {
        backgroundColor: theme.palette.common.white,
        padding: "40px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "7px",
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
    },
}));

export function KYC() {
    const data = useData()?.submissionData;
    const { instId, setSnackMessage, setSnackOpen, setSnackType, needUpdates, setNeedUpdates } =
        useData();
    const navigate = useNavigate();
    const classes = useStyles();
    const onSubmit = () => {
        axios
            .all([
                api.post("/onboarding/docs/certifications/review", {
                    institutionID: instId,
                    documents: data.certifications.map((elem: KYCType["documents"][0]) => ({
                        message: elem.message || "",
                        status: elem.reviewStatus,
                        type: elem.type,
                    })),
                }),
                api.post("/onboarding/docs/identity/review", {
                    institutionID: instId,
                    documents: data.identity.map((elem: KYCType["documents"][0]) => ({
                        message: elem.message || "",
                        status: elem.reviewStatus,
                        type: elem.type,
                    })),
                }),
                api.post("/onboarding/docs/license/review", {
                    institutionID: instId,
                    documents: data.license.map((elem: KYCType["documents"][0]) => ({
                        message: elem.message || "",
                        status: elem.reviewStatus,
                        type: elem.type,
                    })),
                }),
                api.post("/onboarding/docs/policy/review", {
                    institutionID: instId,
                    documents: data.policy.map((elem: KYCType["documents"][0]) => ({
                        message: elem.message || "",
                        status: elem.reviewStatus,
                        type: elem.type,
                    })),
                }),
                api.post("/onboarding/docs/other-documents/review", {
                    institutionID: instId,
                    documents: data.other_documents.map((elem: KYCType["documents"][0]) => ({
                        message: elem.message || "",
                        status: elem.reviewStatus,
                        type: elem.type,
                    })),
                }),
            ])
            .then(() => {
                setNeedUpdates(!needUpdates);
                setSnackMessage(
                    `${
                        data.status === "APPROVED"
                            ? "The step was approved"
                            : "You successfully requested updates"
                    }`,
                );
                setSnackType("success");
                setSnackOpen(true);
                navigate(routePaths.api_review);
            })
            .catch(() => {
                setSnackMessage("This operation failed");
                setSnackType("error");
                setSnackOpen(true);
            });
    };
    const onBack = () => navigate(routePaths.personalization_review);
    return (
        <>
            <SimpleVerticalStepper step={3} />
            <Stack className={classes.container}>
                <Stack className={classes.inner}>
                    <SectionHeader name="KYC Information" needStatus={false} />
                    <KYCContent data={data} />
                </Stack>
                <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                    <Button variant="outlined" onClick={onBack}>
                        Back
                    </Button>
                    <StyledButton
                        text="Save and continue"
                        width="197px"
                        isAccept
                        onHandleClick={onSubmit}
                        isDisabled={false}
                    />
                </Stack>
            </Stack>
        </>
    );
}
