import { Theme } from "@mui/material";

export default function Textfield(baseTheme: Theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    backgroundColor: baseTheme.palette.info.main,
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },

                    "& .MuiInputBase-input": {
                        color: baseTheme.palette.warning.light,
                    },
                },
            },
        },
    };
}
