import React from "react";
import { ReactComponent as Approve } from "assets/Images/approve.svg";
import { ReactComponent as Warning } from "assets/Images/warning.svg";
import { ReactComponent as Rejected } from "assets/Images/rejected.svg";
import { ReactComponent as Wait } from "assets/Images/wait2.svg";
import { Typography } from "@mui/material";
import { ISubmissionRow } from "types/Models";
import { ReviewLabel } from "./ReviewLabel";

export function ReviewCell({ data }: ISubmissionRow) {
    if (data.reviewStatus === "APPROVED") return <ReviewLabel icon={<Approve />} text="Approved" />;
    if (data.reviewStatus === "PENDING_REVIEW")
        return <ReviewLabel icon={<Warning />} text="Pending Review" />;
    if (data.reviewStatus === "REJECTED")
        return <ReviewLabel icon={<Rejected />} text="Rejected" />;
    if (data.reviewStatus === "UPDATE_REQUESTED")
        return <ReviewLabel icon={<Wait />} text="Update Requested" />;
    if (data.reviewStatus === "REVIEW_IN_PROGRESS")
        return <ReviewLabel icon={<Warning />} text="Review in progress" />;
    return (
        <Typography variant="subtitle1" color="text.secondary">
            {" "}
            N/A{" "}
        </Typography>
    );
}
