import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ISubmissionData, KYCType } from "types/Models";
import { AccordionFile } from "./AccordionFile";

interface IKYCPropsType {
    data: ISubmissionData;
}

export function KYCContent({ data }: IKYCPropsType) {
    return data ? (
        <Stack spacing={3}>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Certification
                </Typography>
                <Stack spacing={1}>
                    {data.certifications?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Identity Verification
                </Typography>
                <Stack spacing={1}>
                    {data.identity?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    License Verification
                </Typography>
                <Stack spacing={1}>
                    {data.license?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Policy Documents
                </Typography>
                <Stack spacing={1}>
                    {data.policy?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Other Documents
                </Typography>
                <Stack spacing={1}>
                    {data.other_documents?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} />
                    ))}
                </Stack>
            </Box>
        </Stack>
    ) : (
        <Typography variant="body1" color="error.dark" fontStyle="italic">
            No information to display. Please try again
        </Typography>
    );
}
