import { Theme } from "@mui/material";

export default function Input(baseTheme: Theme) {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    backgroundColor: "#021921",
                    color: baseTheme.palette.common.white,
                    "& .MuiInputBase-input": {
                        color: baseTheme.palette.common.white,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.primary.main,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.primary.main,
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.secondary.main,
                    },
                },
            },
        },
    };
}
