import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { AlertColor, Box, Pagination, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { formatDate } from "helpers";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { INotification } from "types/Models";
import { LinkMoveBack } from "components/LinkMoveBack";
import { routePaths } from "routes/routePaths";
import { Modal } from "components/Modal";
import { getNotifications } from "pages/Dashboard/Dashboard.service";
import { ViewCell } from "./components/ViewCell";
import { DeleteCell } from "./components/DeleteCell";

interface ISubmProps {
    setLoaderOpen: (value: boolean) => void;
    setSnackOpen: (value: boolean) => void;
    setSnackType: (value: AlertColor) => void;
    setSnackMessage: (value: string) => void;
    needTableUpdate: boolean;
    setTableUpdate: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    inner: {
        padding: "25px",
        width: "100%",
        height: "calc(100vh - 110px)",
        gap: "25px",
        marginBottom: "10px",
    },
    sideMenu: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px",
        borderBottom: `2px solid ${theme.palette.secondary.contrastText}`,
        borderColor: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.common.white,
    },
}));

function SubjectCell({ data }: any) {
    return (
        <Stack spacing={1}>
            <Typography variant="button" className="subject" color="text.disabled">
                {data.subject}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" className="mainSelection">
                Bank ID: {data.institutionId}
            </Typography>
        </Stack>
    );
}

function LogIdCell({ data }: any) {
    return (
        <Stack spacing={1}>
            <Typography variant="button" color="text.disabled" className="secondarySelection">
                Log ID: {data.id}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                {formatDate(data.sentTime)}
            </Typography>
        </Stack>
    );
}

export const validationSchema = yup.object({
    reviewStatus: yup.string(),
    submissionStatus: yup.string(),
});

export function Notifications({
    setLoaderOpen,
    setSnackOpen,
    setSnackMessage,
    setSnackType,
    needTableUpdate,
    setTableUpdate,
}: ISubmProps) {
    const gridRef = useRef<AgGridReact<INotification>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);
    const classes = useStyles();
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [gridApi, setGridApi] = useState<GridApi<INotification> | undefined>();
    const [pagesNumber, setPagesNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [isModalOpen, setModalOpen] = useState(false);
    const [needUpdates, setNeedUpdates] = useState(false);

    useLayoutEffect(() => {
        setLoaderOpen(true);
        getNotifications()
            .then((result) => {
                setNotifications(result.data.data);
                setNotificationsCount(result.data.totalCount);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    useLayoutEffect(() => {
        setLoaderOpen(true);
        getNotifications({
            pageNumber: currentPage,
            pageSize: gridApi ? gridApi.paginationGetPageSize() : 5,
        })
            .then((result) => {
                setNotifications(result.data.data);
                setNotificationsCount(result.data.totalCount);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, [currentPage, needUpdates]);

    useLayoutEffect(() => {
        if (gridApi && notificationsCount) {
            setPagesNumber(Math.ceil(notificationsCount / gridApi.paginationGetPageSize()));
        }
    }, [gridApi, notificationsCount, currentPage]);

    const handleClose = () => {
        setModalOpen(false);
    };

    const columnDefs = useMemo(
        () => [
            {
                field: "Subject",
                flex: 1,
                minWidth: 450,
                suppressMovable: true,
                cellRenderer: SubjectCell,
            },
            {
                field: "LogId",
                flex: 1,
                minWidth: 250,
                suppressMovable: true,
                cellRenderer: LogIdCell,
            },
            {
                field: "View",
                cellRenderer: ViewCell,
                cellRendererParams: {
                    setNotification,
                    setModalOpen,
                },
                flex: 1,
                minWidth: 100,
                suppressMovable: true,
            },
            {
                field: "Delete",
                cellRenderer: DeleteCell,
                cellRendererParams: {
                    setLoaderOpen,
                    setSnackType,
                    setSnackMessage,
                    setSnackOpen,
                    needUpdates,
                    setNeedUpdates,
                    needTableUpdate,
                    setTableUpdate,
                },
                flex: 1,
                minWidth: 100,
                suppressMovable: true,
            },
        ],
        [notifications],
    );

    return (
        <Stack>
            <Stack className={classes.inner}>
                <LinkMoveBack
                    url={routePaths.dashboard}
                    text="Dashboard"
                    padding="40px 0"
                    back="#F8F9FC"
                />
                <Stack
                    height="100%"
                    sx={{
                        border: "1px solid",
                        borderColor: ({ palette }) => palette.secondary.contrastText,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                    }}
                >
                    <Stack className={classes.header}>
                        <Typography variant="button" color="common.black">
                            Recent Notifications
                        </Typography>
                    </Stack>
                    <Box className="ag-theme-alpine without-header" style={{ height: "100%" }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowData={notifications}
                            suppressRowClickSelection
                            onGridReady={(params) => setGridApi(params.api)}
                            pagination
                            paginationPageSize={5}
                            paginationAutoPageSize
                            headerHeight={0}
                            onPaginationChanged={() => {
                                if (gridRef?.current?.api?.paginationGetPageSize) {
                                    setPagesNumber(
                                        Math.ceil(
                                            notificationsCount /
                                                gridRef.current.api.paginationGetPageSize(),
                                        ),
                                    );
                                }
                            }}
                        />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            background: ({ palette }) => palette.common.white,
                            padding: "30px",
                            borderTop: "2px solid",
                            borderColor: ({ palette }) => palette.secondary.contrastText,
                        }}
                    >
                        <Pagination
                            count={pagesNumber}
                            ref={paginationRef}
                            onChange={(e, page) => {
                                setCurrentPage(page);
                                if (gridRef.current) {
                                    gridRef.current.api.paginationGoToPage(page - 1);
                                }
                            }}
                            shape="rounded"
                            page={currentPage}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Modal isModalOpen={isModalOpen} handleClose={handleClose} element={notification} />
        </Stack>
    );
}
