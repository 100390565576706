import React from "react";
import { Typography, Stack, Dialog, Theme } from "@mui/material";
import { formatDate } from "helpers";
import { INotification } from "types/Models";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { CloseBtn } from "./CloseBtn";

type ModalPropsType = {
    isModalOpen: boolean;
    handleClose: () => void;
    element: INotification | undefined;
};

const BottomSectionStyled = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "15px",
    alignSelf: "start",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
    },
}));

const TitleStyled = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
    },
}));

const MessageStyled = styled(Typography)(({ theme }) => ({
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
        fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
    },
}));

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "40vw",
        minHeight: "40vh",
        padding: "40px 50px",
        boxSizing: "border-box",
        justifyContent: "space-between",
        gap: "20px",
        [theme.breakpoints.down("md")]: {
            padding: "20px",
            width: "80vw",
            minHeight: "40vh",
        },
        [theme.breakpoints.down("sm")]: {
            width: "80vw",
            minHeight: "60vh",
        },
    },
}));

export function Modal({ element, isModalOpen, handleClose }: ModalPropsType) {
    const classes = useStyles();
    return (
        <Dialog open={isModalOpen}>
            <Stack className={classes.container}>
                <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between" width="100%" spacing={2}>
                        <TitleStyled variant="h2" color="primary.dark">
                            {element && element.subject}
                        </TitleStyled>
                        <CloseBtn handleClose={handleClose} />
                    </Stack>
                    <MessageStyled variant="h4" maxWidth="400px" color="info.dark">
                        {element && element.message.replace("\n ", "\n")}
                    </MessageStyled>
                </Stack>
                <BottomSectionStyled>
                    <Typography variant="button" align="center" color="primary.main">
                        Log Ref: {element && element.id}
                    </Typography>
                    <Typography variant="body2" align="center" color="action.disabled">
                        {element && formatDate(element.sentTime)}
                    </Typography>
                </BottomSectionStyled>
            </Stack>
        </Dialog>
    );
}
